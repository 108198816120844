import StaffLogin from './pages/StaffLogin';
import AdminLogin from './pages/AdminLogin';
import LandingPage from './pages/LandingPage';
import Header from './components/Header';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import './firebase/config'
import {UserProvider} from './firebase/UserProvider'
import Dashboard from './pages/Dashboard'
import GlobalStyle from './themes/globalStyles'
import NewCustomer from './pages/NewCustomer'
import EditCustomer from './pages/EditCustomer';
import Customers from './pages/Customers'
import Architects from './pages/Architects'
import NewArchitect from './pages/NewArchitect';
import EditArchitect from './pages/EditArchitect';
import MergeArchitect from './pages/MergeArchitect';
import MergeCustomer from './pages/MergeCustomer';
import NewSupplier from './pages/NewSupplier';
import Suppliers from './pages/Suppliers';
import MergeSupplier from './pages/MergeSupplier';
import EditSupplier from './pages/EditSupplier';
import ProductCategories from './pages/Products';
import MergeProductCategory from './pages/MergeProduct';
import EditProductCategory from './pages/EditProduct';
import NewProductCategory from './pages/NewProduct';
import Unauth from './pages/Unauth'
import CustomerOrders from './pages/CustomerOrders';
import ISR from './pages/ISR';
import NewISR from './pages/NewISR';
import MergeISR from './pages/MergeISR';
import EditISR from './pages/EditISR';
import OSR from './pages/OSR';
import NewOSR from './pages/NewOSR';
import MergeOSR from './pages/MergeOSR';
import EditOSR from './pages/EditOSR';
import CO from './pages/CustomerOrder';
import NewCO from './pages/NewCustomerOrder'
import EditCO from './pages/EditCO';
import Payment from './pages/payments';
import NewPayment from './pages/NewPayment';
import Payments from './pages/payments';
import PrivateRoute from './components/PrivateRoute';
import CustomerOrdersMaster from './pages/CO_master';
import ExcelExport from './pages/COMasterExcel';

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Header />
        <Route exact path = '/staff-login' component = {StaffLogin}/>
        <Route exact path = '/staff-login/uh-oh' component = {Unauth}/>
        <Route exact path = '/admin-login' component = {AdminLogin}/>
        <GlobalStyle />
        <PrivateRoute exact path = '/dashboard/:id' component = {Dashboard}/>

        <PrivateRoute exact path = '/customer-orders/' component = {CustomerOrders}/>
        <PrivateRoute exact path = '/customer-orders-master-data/' component = {CustomerOrdersMaster}/>
        <PrivateRoute exact path = '/customer-orders-master-data/excel' component = {ExcelExport}/>
        <PrivateRoute exact path = '/customer-orders/new-customer-order' component = {NewCO} editing={false}/>
        <PrivateRoute exact path = '/customer-orders/view/:id' component = {CO}/>
        <PrivateRoute exact path = '/customer-orders/edit/:id' component={EditCO}/>

        <PrivateRoute exact path = '/customers' component = {Customers}/>
        <PrivateRoute exact path = '/architects' component = {Architects}/>
        <PrivateRoute exact path = '/architects/new-architect' component = {NewArchitect} editing={false}/>
        <PrivateRoute exact path = '/architects/edit-architect/:id' component={EditArchitect}/>
        <PrivateRoute exact path = '/architects/merge' component={MergeArchitect}/>
        <PrivateRoute exact path = '/customers/new-customer' component = {NewCustomer} editing={false}/>
        <PrivateRoute exact path = '/customers/edit-customer/:id' component={EditCustomer}/>
        <PrivateRoute exact path = '/customers/merge' component={MergeCustomer}/>
        <Route exact path = '/' component = {LandingPage} />

        <PrivateRoute exact path = '/suppliers' component = {Suppliers}/>
        <PrivateRoute exact path = '/suppliers/new-supplier' component = {NewSupplier} editing={false}/>
        <PrivateRoute exact path = '/suppliers/merge' component={MergeSupplier}/>
        <PrivateRoute exact path = '/suppliers/edit-supplier/:id' component={EditSupplier}/>

        <PrivateRoute exact path = '/ISRs' component = {ISR}/>
        <PrivateRoute exact path = '/ISRs/new-isr' component = {NewISR} editing={false}/>
        <PrivateRoute exact path = '/ISRs/merge' component={MergeISR}/>
        <PrivateRoute exact path = '/ISRs/edit-isr/:id' component={EditISR}/>

        <PrivateRoute exact path = '/OSRs' component = {OSR}/>
        <PrivateRoute exact path = '/OSRs/new-osr' component = {NewOSR} editing={false}/>
        <PrivateRoute exact path = '/OSRs/merge' component={MergeOSR}/>
        <PrivateRoute exact path = '/OSRs/edit-osr/:id' component={EditOSR}/>

        <PrivateRoute exact path = '/product-categories' component = {ProductCategories}/>
        <PrivateRoute exact path = '/product-categories/new-product-category' component = {NewProductCategory} editing={false}/>
        <PrivateRoute exact path = '/product-categories/merge' component={MergeProductCategory}/>
        <PrivateRoute exact path = '/product-categories/edit-product-category/:id' component={EditProductCategory}/>

        <PrivateRoute exact path = '/payments' component={Payments}/>
        <PrivateRoute exact path = '/payments/new-payment' component={NewPayment}/>
        <PrivateRoute exact path = '/payments/passbook/:id' component = {Payments}/>
        <PrivateRoute exact path = '/payments/cancel/:id' component = {NewPayment}/>


      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
