import React from 'react'
import styled from 'styled-components'

const ExistPopup =(props) => {
    return (
        <PopupS>
            <PopupContentS>
                <header>
                    <h2>{props.header}</h2>
                    <Close onClick={props.close}>x</Close>
                </header>
                {props.content.split('\n').map((str, i) => <p key={i}>{str}</p>)}
                {props.edit?<Edit onClick={props.edit}>Edit</Edit>:""}
            </PopupContentS>
        </PopupS>
    );
}

const PopupS = styled.div`
    width: 100vw;
    height: 100vh;
    background: #00000050;
    z-index: 2;
    position: fixed;
`;

const PopupContentS = styled.div`
    position: absolute;
    background-color : ${({theme}) => theme.colors.white};
    width: 20%;
    height: 50%;
    margin-top: auto;
    margin-bottom:auto;
    top:0;
    bottom:0;
    margin:auto;
    left:0;
    right: 0;
    border-radius: 1rem;
    padding: 1rem 2rem;
    overflow-y: auto;
    overflow-wrap: break-word;
    
`;

const Close = styled.button`
    position: absolute;
    top:1rem;
    right:1rem;
    background: none;
    border: none;
    font-size: 1rem;
`;

const Edit = styled.button`
    background: none;
    padding:0.3rem 0.8rem;
    margin: 0.3rem;
    text-decoration:none;  
    border-radius: 9px;
    border: 1px solid ${ ({theme}) => theme.colors.orange};
    box-sizing: border-box;
    font-family: ${ ({theme}) => theme.fonts.text}, sans-serif;
    letter-spacing: 0px;
    line-height: 1.2;
    color:  ${ ({theme}) => theme.colors.orange};
    font-size: 0.8rem;
    &:focus{
        outline: none;
    }
`;

export default ExistPopup;