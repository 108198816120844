import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const NewCustomer =  (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }, reset} = useForm();
    const [exists, setExists] = useState(false);
    const [content, setContent] = useState({header: "Customer Already Exists", text: ""});
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false)
    // useEffect(() => {
    //     // console.log("Use Effect Called");
    //     if(props.editing){
    //         // console.log(props.user)
    //         const formData = Object.entries(props.user).map((entry) => ({
    //             [entry[0]]: entry[1],
    //         }));
    //         console.log("formData:",formData);
    
    //         setValue(formData);
    //     };
    // },[]);
        
    useEffect(()=>{
        if(props.editing){
            console.log('user to be editted:', props.user);
            reset(props.user);
        }
    }, []);

    const close = () =>{
        setExists(false);
    }

    const edit = ()=> {
        props.history.push(`/customers/edit-customer/${user.uid}`, {user : user});
    }

    const createUser = async (user) =>{
        setLoading(true)
        setUser(user);
        const doc =  await firestore.doc(`/customers/${user.uid}`).get();
        if(doc.exists){
            // console.log(doc.data())
            setContent({...content, text: `ID: ${doc.data()['uid']}\n
                                            Name: ${doc.data()['firstname']} ${doc.data()['lastname']}\n
                                            Company: ${doc.data()['company']}\n
                                            Address: ${doc.data()['address']}\n
                                            Credit authorisation: ${doc.data()['credit_auth']}\n
                                            Credit limit: ${doc.data()['credit_limit']}\n`});
            setExists(true);
        } 
        else {
            const docRef = firestore.doc(`/customers/${user.uid}`);
            // create user object
            const userProfile = {
                uid: user.uid,
                firstname: user.firstname,
                lastname: user.lastname,
                address: user.address,
                company: user.company,
                credit_auth: user.credit_auth,
                credit_limit : user.credit_limit? parseInt(user.credit_limit):0,
                wallet: 0,    //negative wallet means pending amount
                payments:[],
                COs: []
            };

            // write to Cloud Firestore
            await docRef.set(userProfile);
            props.history.replace('/customers');
             
        }
        setLoading(false)
    }

    const cancel = () => {
        props.history.replace('/customers');
    }

    const update = async (newCustomer) =>{
        console.log(newCustomer);
        const docRef = firestore.doc(`/customers/${props.user.uid}`);
        await docRef.update({...newCustomer, uid:props.user.uid, credit_limit : newCustomer.credit_limit? parseInt(newCustomer.credit_limit):0});
        props.history.push('/customers');
    }


    return(
        <>
        <Body>
            <Title>New Customer</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(createUser)}>
                <Table><tbody>
                    
                    <tr>
                        <td>Customer ID</td><td><Input 
                                                    error={errors.uid && errors.uid.type == "required"} 
                                                    key={1} 
                                                    type='tel' {...register('uid', { required: !props.editing })} 
                                                    placeholder = "Customer ID"
                                                    disabled={props.editing?true:false} 
                                                    defaultValue={props.editing?props.user.uid:""}
                                                    /></td>
                    </tr>
                    <tr>
                        <td>First Name</td><td><Input 
                                                    error={errors.firstname && errors.firstname.type === "required"} 
                                                    key={2} 
                                                    type='text' {...register('firstname', { required: true })} 
                                                    placeholder = "First Name"
                                                    defaultValue={props.editing?props.user.firstname:""}/> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Last Name</td><td><Input 
                                                error={errors.lastname && errors.lastname.type === "required"} 
                                                key={3} 
                                                type='text' {...register('lastname', { required: true })} 
                                                placeholder = "Last Name"
                                                defaultValue={props.editing?props.user.lastname:""}/> 
                                                </td>
                    </tr>
                    <tr>
                        <td>Company</td><td><Input key={4} type='text' {...register('company')} placeholder = "Company (optional)"
                                                defaultValue={props.editing?props.user.company:""}/> </td>
                    </tr>
                    <tr>
                        <td>Address</td><td><Input 
                                                error={errors.address && errors.address.type === "required"} 
                                                key={5} 
                                                type='text' {...register('address', { required: true })} 
                                                placeholder = "Address"
                                                defaultValue={props.editing?props.user.address:""}/> </td>
                    </tr>
                    <tr> 
                        <td>Credit Authorisation</td><td><Select error={errors.credit_auth && errors.credit_auth.type === "required"} key={6}  {...register('credit_auth', { required: true })} defaultValue={props.editing?props.user.credit_auth:"No"}>
                                                                <Option value="No">No</Option>
                                                                <Option value="L1">L1</Option>
                                                                <Option value="L2">L2</Option>
                                                         </Select> </td>
                    </tr>
                    <tr>
                        <td>Credit Limit</td><td><Input 
                                                    error={errors.credit_limit && errors.credit_limit.type === "required" && watch('credit_auth') =='L2'} 
                                                    key={7} 
                                                    disabled={watch('credit_auth') =='L2'?false:true} 
                                                    type='number' {...register('credit_limit', { required: watch('credit_auth') =='L2'})} 
                                                    placeholder = "Credit limit"
                                                    defaultValue={props.editing?props.user.credit_limit:0}/> </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {props.editing? <Button onClick={handleSubmit(update)} color={'green'}>Update</Button> :
                            <Button type = 'submit' value='Submit' color={'green'}>Create Customer</Button>}
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

           {loading? <></>: <AuthInfo />}
            
        </Body>
        {exists? <ExistPopup content = {content.text} header={content.header} edit={edit} close={close}/> :""}
        </>
    );
}

const AuthInfo = () =>{
    return(
        <AuthInfoS>
            <Text>About </Text><Text color = 'orange'>Credit Authorisation</Text><br/>
            <Text size='small' color = 'orange'>No: </Text><Text size='small'>Customer order can't be created if wallet amount {'<'} 30% * (C/O value)</Text><br/>
            <Text size='small' color = 'orange'>L1: </Text><Text size='small'>Customer order can be created. Gatepass cant be generated if pending balance {'<'} 0</Text><br/>
            <Text size='small' color = 'orange'>L2: </Text><Text size='small'>Customer order and gatepass can be created and would be subject to Credit Limit {'>'} Pending amount</Text>            
        </AuthInfoS>
    );
}


export default NewCustomer;

