import styled from 'styled-components';

export const Title = styled.h1`
    color: ${({theme}) => theme.colors.white};
    // line-height: -10;
    font-weight: 700;
    font-style: normal;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    letter-spacing: -2px;
    font-size: 106px;
    margin-bottom: 0;
`;

export const Button = styled.button`
    border: none;
    // position:absolute;
    margin : 0rem 0 2rem 0;
    display : block ;
    text-decoration : none;
    width: 350px;
    height: 42px;
    box-shadow: 1px 1px 4px 1px #777777;
    background-color: ${ ({theme}) => theme.colors.seaBlue};
    border-radius: 12px;
    line-height: 1.2;
    font-size: 21px;
    font-weight: 599;
    letter-spacing: 0.2px;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    color : ${ ({theme}) => theme.colors.white};
    &: focus {
        outline : none;
        box-shadow: none;
    }
    z-index:2;
`;

export const LandingPageS = styled.div`
    height : 100vh;
    width : 100vw;
    overflow: hidden;
    background-color : ${ ({theme}) => theme.colors.darkBlue};
    .info{
        padding: 0rem 10rem;
        position: absolute;
        z-index:3;
    }
    position:absolute;
`;
