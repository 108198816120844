import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {set, useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Merge} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import ComingSoon from '../components/ComingSoon'
import styled from 'styled-components'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'
import { CoInfo, Table, Box } from '../styles/CO_master.style';
// import { Table } from '../styles/CO_master_2.style';
import {Buttons} from '../styles/CustomerOrder.style'
import ExistPopup from '../components/ExistPopup'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/co_master.css";
import firebase from 'firebase/app'
import { makeExcelData } from './COMasterExcel';


const CustomerOrdersMaster = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [customerOrders, setCustomerOrders] = useState([]);
    const [display, setDisplay] = useState([]);
    const [popup, setPopup] = useState({comingSoon:false});
    const [temp, setTemp] = useState();
    const [id, setId] = useState(1);
    const [loading, setLoading] = useState(false)
    let fetchCount = 10;
    const [lastFetchId, setLastFetchId] = useState(Number.MAX_SAFE_INTEGER);
    const [hasMore, setHasMore] = useState(true)
    const [history, setHistory] = useState(false);
    const [content, setContent] = useState({header: "History", text: ""});
    const [updateUI, setUpdateUI] = useState(1)
    const [test, setTest] = useState(1)
    const [searching, setSearching] = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [UI, setUI] = useState([])

    useEffect( () => {
        fetchData();
    }, [])
        
    const fetchData = async () => {
        await firestore.collection("customer-orders").orderBy("uid", "desc").startAfter(lastFetchId).limit(fetchCount).get().then(async (querySnapshot) => {
            await fetchDataHelper(querySnapshot, fetchCount, false);  // separated this so that it can be called while search
        })
    };

    const fetchDataHelper = async (querySnapshot, fetchCount2, searching) => {
        let displayTemp = [];
        console.log(querySnapshot.size)
        let count = 0;
        querySnapshot.forEach(async doc => {
            count += 1;
            if(doc.id!=='id'){
                let a = doc.data();
                
                displayTemp = [...displayTemp, {...a}];
                console.log(displayTemp, doc.id)
                if(count == fetchCount2 || doc.id == 0){
                    if(doc.id == 0){
                        setHasMore(false)
                    }
                    // console.log("count reached with display temp: ", displayTemp)
                    //set last updated id
                    await updateDisplay(displayTemp)
                    if(!searching){
                        setDisplay([...display, ...displayTemp])
                        setCustomerOrders([...customerOrders, ...displayTemp])
                        setLastFetchId(parseInt(doc.id));
                    }
                    else{
                        setDisplay([...displayTemp])
                        // setCustomerOrders([...displayTemp])
                    }
                    
                    // console.log('display temp before generating UI: ', displayTemp[0].architect)
                    // let prospectiveUI = await generateUI(displayTemp)
                    // console.log("prospectiveUI: ", prospectiveUI)
                    // setUI(prospectiveUI)
                }
                
            }
        });
    }


    const history_toggle = (hist) => {
        // e.preventDefault();
        setContent({...content, text:hist});
        // const docRef = firestore.doc(`/customer-orders/0/order/0`);
        // await docRef.set({history:content});
        setHistory(true);
    };

    const updateDisplay = async (dTemp) =>{
        dTemp =  dTemp.map( async (a) => {
            let cust = await a.customer.get();
            let arch = await a.architect.get();
            let isr = await a.isr.get();
            let osr = await a.osr.get();
            a.architect = arch.exists?arch.data():{};  
            a.isr = isr.exists?isr.data():{};
            a.osr = osr.exists?osr.data():{};
            cust = cust.data();
            a.customer = cust;
            a.comp = a.comp?'Yes':'No';
            a.items = [];

            //item details
            await firestore.collection(`customer-orders/${a.uid}/order`).get().then(async (querySnapshot) => {
                querySnapshot.forEach(async itemDoc => {
                    if(itemDoc.id!=='id'){
                        console.log(`inside item loop for item id ${a.uid} and prod id ${itemDoc.id} `)
                        let b = itemDoc.data();
                        let prod = await itemDoc.data().prod_category.get();
                        let supp = await itemDoc.data().supplier.get();
                        prod = prod.data(); supp = supp.data();
                        b.supplier = supp;b.prod_category = prod;
                        // display.map( (item) => item.uid == a.uid? {...item, items:[...item.items,b]}:item)
                        a.items = [...a.items, {...b}];
                        console.log("a.items:", a.items)
                        setUpdateUI(updateUI+1)
                        // console.log("display: ", display)

                    }
                })
            });

        })
        dTemp = await Promise.all(dTemp)
        // dTemp.map(async a =>await Promise.all(a.items))

        // setDisplay([...display, ...dTemp])
        
    }

    const newCustomer =()=>{
        props.history.push('/customer-orders/new-customer-order')
    };

    
    const close = ()=>{setHistory(false)}

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const openCO = (e)=>{
        let co = customerOrders.filter((co)=>co.uid==e)[0];
        props.history.push(`/customer-orders/view/${e}`,{co:co});
    };

    // useEffect( ()=>{console.log("useffect display items: ",display[0]?.customer.payments)}, [display])
    
    useEffect(() => {
        // let timeout = updateUI<=2?1000:0
        let timeout = 500
        const tempFun = () => {console.log('called with uiupdate: ', updateUI)
        console.log("display while setiing ui: ", display)
        // console.log("dTemp in generateUI:, ",dTemp)
           let toret = []
            // console.log("0th of 0th: ",dTemp[0].items.length)
            display.forEach(async (item,id) => {
                // console.log("item.items: ", item.items)      
                // console.log("dtemp for each: ", item);
                            
                item.items.forEach( (prod, iid) => {
                    // console.log("items for each: ", prod);
                    let a = <TR key={`${id}${iid}`} status={prod.status}>
                        {iid==0? 
                            <td style={{backgroundColor:'none'}}rowSpan={item.items.length}>
                                <CoInfo style={{margin:5,padding:10, backgroundColor:'white'}}>
                                    <tbody>
                                        <tr><th>Date</th><td>{item.date}</td> </tr>
                                        <tr><th>Cust ID</th><td>{item.customer.uid}</td> </tr>
                                        <tr><th>Cust Name</th><td>{`${item.customer.firstname} ${item.customer.lastname}`}</td> </tr>
                                        <tr><th>CO ID</th><td>{item.uid}</td> </tr>
                                        <tr><th>Order no.</th><td>{item.billno}</td></tr>
                                        <tr><th>Status</th><td>{item.status}</td></tr>
                                        <tr><th>Amount</th><td>{item.total}</td></tr>
                                        <tr><th>Architect</th><td>{isEmpty(item.architect)?'-':`${item.architect.firstname} ${item.architect.lastname}`}</td></tr>
                                        <tr><th>Comp</th><td>{item.comp}</td></tr>
                                        <tr><th>ISR</th><td>{isEmpty(item.isr)?'-':item.isr.name}</td></tr>
                                        <tr><th>OSR</th><td>{isEmpty(item.osr)?'-':item.osr.name}</td></tr>
                                    </tbody>
                                </CoInfo>
                            </td>
                        :<></>}
                        <td>{prod.prod_collection}</td>
                        <td>{prod.prod_sno}</td>
                        <td>{prod.supplier?.name}</td>
                        <td>{prod.prod_category?.name}</td>
                        <td>{prod.qty}</td>
                        <td>{prod.price}</td>
                        <td>{prod.gst}</td>
                        <td>{prod.discount}</td>
                        <td>{Math.round(prod.qty * prod.price*(1+prod.gst/100)*(1-prod.discount/100)*100)/100}</td>
                        <td>{prod.receiving_date?prod.receiving_date.toDate().toLocaleDateString():'-'}</td>
                        {/* <td onClick={()=>history_toggle(prod.history)}><Buttons>View</Buttons></td> */}

                        {/* <td>{prod.status!="cancelled" && metadata.status!='cancelled'?<EditIcon onClick={()=>enableEdit(prod.uid)} src={editIcon}/>:''}</td> */}
                    </TR>
                    toret = [...toret, a]
                });
            })
            console.log("troet:", toret)
            setUI(toret)
            setLoading(false)}
        setTimeout(
            tempFun, timeout)
            setLoading(false)
        }, [updateUI])

    
        const updateSearchResultsDate = async (e) => {
            if(startDate == null){
                setSearching(false)
                // setLastFetchId(Number.MAX_SAFE_INTEGER)
                setDisplay(customerOrders)
                setUpdateUI(updateUI+1)
                // setCustomerOrders([])
                // await fetchData()
                return
            }
            console.log(startDate, endDate)
            setLoading(true)
            setDisplay(display => [])
            let date1 = firebase.firestore.Timestamp.fromDate(startDate)
            let date2 = firebase.firestore.Timestamp.fromDate(endDate)
            setSearching(true)
            await firestore.collection("customer-orders").orderBy('dateStamp','desc').where('dateStamp', '>=',date1 ).where('dateStamp', '<=', date2).get().then(async (querySnapshot) => {
                console.log(`inside updateSearchResultsOrderNo await`)
                if(querySnapshot.size == 0) {
                    setLoading(false)
                    setUI(<p style={{margin:'1rem'}}>No data available</p>)
                }
                else
                await fetchDataHelper(querySnapshot, querySnapshot.size, true);  
            })
        };

        const updateSearchResultsOrderNo = async (e) => {
            e.preventDefault();
            setLoading(true)
            setDisplay(display => [])
            let toSearch = e.target[0].value.toUpperCase(); 
            let searchEnd  = toSearch.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
            setSearching(true)
            await firestore.collection("customer-orders").where('billno', '==',toSearch ).get().then(async (querySnapshot) => {
                console.log(`inside updateSearchResultsOrderNo await`)
                if(querySnapshot.size == 0) {
                    setLoading(false)
                    setUI(<p style={{margin:'1rem'}}>No data available</p>)
                }
                else
                await fetchDataHelper(querySnapshot, querySnapshot.size, true);  
            })
            // setUpdateUI(updateUI+1)
            // updateSearchResultsHelper(toSearch);
        };

        const updateSearchResultsHelper = async (toSearch) => {

        } 

    const exportToExcel = () =>{
        const data = makeExcelData(display);
        props.history.push(`/customer-orders-master-data/excel/`, {data:data});
    }

    return(
        <>
        <Body2>
            {console.log("UI master data: ", UI)}
            <Title>Customer Orders Master Data</Title>
            
            <Box id='infiniteScrollableTarget'>
                <header style={{display:'flex', zIndex:10000}}>
                    <NewDoc src={newDoc} onClick={newCustomer}/>  {/*<Merge type='submit'>Merge</Merge> */}
                    {/* <form onSubmit={updateSearchResultsDate}> <SearchBar  style={{width:'20rem', margin:'0 1rem 0 1rem'}} placeholder="Search by date (dd/mm/yyyy) "/> </form> */}
                    <DatePicker className='datePicker' selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                }}
                                placeholderText="Select Date Range"
                                isClearable={true}

                                />
                    <Merge onClick={updateSearchResultsDate}>Search</Merge>
                    {!loading && searching ? <Merge onClick={exportToExcel}>Export to Excel</Merge>:<></>}
                    {/* <Merge type='submit' onSubmit={async ()=> await updateSearchResultsDate}>Search</Merge></form> */}
                    <form onSubmit={updateSearchResultsOrderNo}> <SearchBar  style={{width:'20rem', margin:'0 1rem 0 1rem'}} placeholder="Search by order no."/> </form>
                    {/* <Merge type='submit' onSubmit={async ()=> await updateSearchResultsOrderNo}>Search</Merge></form> */}
                    {/* <Merge type="button" onClick = {()=> setTest(test+1)}> test </Merge> */}
                </header>
                {loading? <Loading><div class='dot-spin'/></Loading> :
                <InfiniteScroll
                            dataLength={display.length-1}
                            next={searching? ()=> {} : fetchData}
                            hasMore={searching?false:hasMore}
                            loader={searching? <></> :<h4 style={{margin:'1rem'}}>Loading...</h4>} 
                            scrollableTarget="infiniteScrollableTarget"
                            // endMessage={
                            //     <p style={{ textAlign: "center" }}>
                            //       <b>Yay! You have seen it all</b>
                            //     </p>
                            //   }
                            >
                    <Table>
                        <colgroup>
                            <col span="1" style={{width: '18%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '10%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '5%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            <col span="1" style={{width: '9%'}}/>
                            <col span="1" style={{width: '7%'}}/>
                            {/* <col span="1" style={{width: '5%'}}/> */}
                        </colgroup>
                        
                        <tbody>
                        
                            <tr id='head' style={{position:'sticky'}}>
                                <th>CO Details</th>
                                <th>Prod Collection</th>
                                <th>Prod SNo/Color</th>
                                <th>Supplier</th>
                                <th>Prod Category</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>GST(%)</th>
                                {/* <th>Total Pending</th> */}
                                <th>Discount(%)</th>
                                <th>Amount</th>
                                <th>Receving Date</th>
                                {/* <th>History</th> */}
                            </tr>
                            
                            {UI}
                            
                      
                        </tbody> 
                    </Table>
                </InfiniteScroll> }
            </Box>
            
        </Body2>
        {history? <ExistPopup content = {content.text} header={content.header} edit={false} close={close}/> :""}
        {/* {popup.comingSoon? <ComingSoon close={close}/>:""} */}
        </>
    );
}

const TD = styled.td`
    color: ${ ({theme, status})=> status=='pending'?theme.colors.orange:status=='blocked'?theme.colors.red:status=='cancelled'?theme.colors.darkGrey:theme.colors.green}
`;

const TR = styled.tr`
    background-color:${ ({theme, status})=> status=='cancelled'?theme.colors.darkGrey:
                            status=='received'?theme.colors.green:
                                status =='ordered'?theme.colors.amber:
                                    theme.colors.white}50;
    // background-opacity:0.5;
`;

export const NewDoc = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.5rem;
`;

export default CustomerOrdersMaster;


