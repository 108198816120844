import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {New, Body2, SearchBar, SearchButton, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import {Buttons, CoInfo, Box2, Cust, CustInfo, CustTables} from '../styles/CustomerOrder.style'
import {Input,Button, Option,Select,Form, AuthInfoS} from '../styles/NewCustomer.style'
import {useUser} from '../firebase/UserProvider'
import "../styles/broader-select-search.css";
import SelectSearch from 'react-select-search';
import { CustDetails } from '../components/CustomerOrderHelpers'
import styled from 'styled-components'
import firebase from 'firebase/app'
import { Loading } from './CustomerOrder'

const NewPayment = (props) => {
    const [custOptions, setCustOptions] = useState([]);
    const [updated, setUpdated] = useState({});
    const [cust, setCust] = useState("");
    const [currentId, setCurrentId] = useState(0);
    const [date,setDate] = useState();
    const [payment, setPayment] = useState(0);
    const [paymentMode, setPaymentMode] = useState("Cash");
    const [warning, setWarning] = useState("");
    const [description, setDescription] = useState("New Payment");
    const [loading, setLoading] = useState(false)

    useEffect( ()=>{
        const fetchId = async () => {
            const idRef = firestore.doc(`/payments/id`);
            const id = (await idRef.get()).data()['id'];
            setCurrentId(id+1);
        };

        const fetchValues = async () => {
            let custs = [];
            await firestore.collection("customers").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    custs = [...custs, doc.data()];
                });
            });
            setCustOptions(custs.map((item) => ({name:item.uid, value:item.uid, item:item})));
        };

    if(props.location.state?.payment){
        setPayment(props.location.state.payment.amount)
        setDescription("Issuing Refund")
    }
    else{
        fetchId();
        fetchValues();
    }
    
    let today = new Date();
    let dateTemp = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() ;
    setDate(dateTemp);
    }, []);

    const handleFilterCustomers = (items) => {
        return (searchValue) => {
                if (searchValue.length === 0) {
                return custOptions;
            }
            const updatedItems = items.filter((item) => {
                return `${item.item.firstname} ${item.item.lastname}`.toLowerCase().includes(searchValue.toLowerCase()) ||  item.value.toString().includes(searchValue.toLowerCase());
            });
            return updatedItems;
        };
    };

    const handleChangeCust = (...args) => {
        setUpdated( {...updated, customer:args[1].value});
        // console.log(args[1]);
        setCust(args[1].item);
    };

    const notePaymentMode = (e) => {
        e.preventDefault();
        setPaymentMode(e.target.value);
        // console.log(e.target.value)
    }
    const notePayment = (e) => {
        e.preventDefault();
        setPayment(e.target.value);
        // console.log(e.target.value)
    }

    const cancel = () => {
        props.history.goBack();
    };
    
    const pay = async (mode) => {
        setLoading(true)
        let paid = 0;
        if(payment == 0 )
            setWarning('Please enter a non-zero amount');
        else{
            if(cust == "" && !props.location?.state?.payment)
                setWarning('Please select a Customer')
            else{
                // create a payment doc
                // add payment id to customer payments
                // update payments id doc

                
                
                const paymentObj = props.location?.state?.payment?{ // its a refund if this exists
                    debit: props.location.state.payment.debit+ parseInt(payment),
                    description: `${props.location.state.payment.description}\n${description}`,
                    status:'refunded'
                }:{
                    uid: parseInt(currentId),
                    date: date,
                    customer: parseInt(cust.uid),
                    credit: parseInt(payment),
                    debit: 0,
                    mode: paymentMode,
                    description:description,
                    status:'default'
                }
                if(props.location?.state?.payment){
                    const docRef = firestore.doc(`/payments/${props.location.state.payment.uid}`);
                    docRef.update(paymentObj);
                }
                else{
                    const docRef = firestore.doc(`/payments/${currentId}`);
                    docRef.set(paymentObj);
                }
                

                if(!props.location?.state?.payment){
                    const idRef = firestore.doc(`/payments/id`);
                    idRef.update({'id': currentId});

                    var custRef = firestore.doc(`/customers/${cust.uid}`);
                        await custRef.update({ 
                            payments: firebase.firestore.FieldValue.arrayUnion(currentId),
                            wallet: firebase.firestore.FieldValue.increment(payment)
                        });
                }
                else{
                    var custRef = firestore.doc(`/customers/${props.location.state.payment.customer}`);
                    await custRef.update({ 
                        wallet: firebase.firestore.FieldValue.increment(-payment)
                    });
                }

                props.location?.state?.payment? props.history.replace(`/payments/passbook/${props.location?.state?.payment.customer}`, {uid:props.location?.state?.payment.customer}): props.history.replace(`/payments/passbook/${cust.uid}`, {uid:cust.uid})
            }
        }
        setLoading(false)
    };

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }


    return (
        <>
            {/* {console.log('payment value: ', -payment)} */}
            <Body2>
                <Title>{props.location.state?.payment?`Cancel Payment No. ${props.location?.state?.payment.uid}`:'New Payment'}</Title> 
            {loading? <Loading><div class='dot-spin'/></Loading> :
                <Box2>
                    <CoInfo width='40' className="newSelect"><tbody>  
                        <tr>
                            <td>Payment ID</td><td>{props.location?.state?.payment?props.location?.state?.payment.uid:currentId}</td>
                        </tr>
                        <tr>
                            <td>Date</td><td>{date}</td>
                        </tr>
                        <tr><td>Customer ID:</td>
                            {props.location?.state?.payment?props.location?.state?.payment.customer:<SelectSearch 
                                key={0} 
                                // ref={searchInput}
                                options={custOptions} 
                                filterOptions={handleFilterCustomers} 
                                search
                                onChange={handleChangeCust}
                                placeholder="Search by ID or Name" />}
                        </tr>
                        {props.location?.state?.payment?<></>:<tr><td>Customer Name:</td><td>{cust?`${cust.firstname} ${cust.lastname}`:""}</td></tr> } 
                        <tr><td>Payment Mode:</td><td>{props.location?.state?.payment?props.location?.state?.payment.mode: <Select onChange={notePaymentMode} defaultValue="Cash">
                                                            <Option value="Cash">Cash</Option>
                                                            <Option value="Cheque">Cheque</Option>
                                                            <Option value="Online">Online</Option>
                                                            <Option value="Paytm">Paytm</Option>
                                                         </Select>}
                                                    </td></tr>
                        <tr> <td>Amount</td>
                            <td>
                                <Input onChange={notePayment} placeholder="Enter Amount" defaultValue={props.location?.state?.payment?props.location.state.payment.credit:''}></Input>
                            </td>
                        </tr>
                        <tr> <td>Remarks</td>
                            <td>
                                <Input onChange={(e)=>{setDescription(e.target.value)}} defaultValue={props.location?.state?.payment?"Issuing refund":"New Payment"}></Input>
                            </td>
                        </tr>
                        <tr><td></td>
                            <td>
                            <Button onClick={props.location?.state?.payment?()=>{pay('debit')}:()=>{pay('credit')}} color={'green'}>{props.location?.state?.payment?'Refund':'Pay'}</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                            </td>
                        </tr>
                        <tr>
                            <td></td><td style={{color:'red'}}>{warning}</td>
                        </tr>
                                                    
                    </tbody></CoInfo>
                    {cust?<CustDetails margin='100' metadata={{customer:cust}}/>:<></>}
                </Box2>}



            </Body2>
        </>
    )

};

export default NewPayment;

// const CustDetailsStyled = CustDetails.styled`
//     margin: 0 0 0 20px,
// `;