
var WebFont = require('webfontloader');
 
WebFont.load({
  google: {
    families: ['Source Sans Pro', 'Varela Round', 'Inter']
  }
});

export default {
    colors: {
        darkBlue : '#34495e',
        grey1 : '#ecf0f1',
        greyInputs : '#EBEBEB',
        seaBlue : '#1abc9c',
        orange : '#ffaf59',
        white : '#ffffff',
        darkGrey : '#333333',
        red : '#EE4444',
        green : '#66BB66',
        amber : '#FFBF00'
    },
    fonts : {
        titles : 'Source Sans Pro',
        header : 'Varela Round',
        text : 'Inter'
    },
    fontSizes : {
        headers : '24px',
        palantirHeader : '35px',
        th : '18px',
        td : '16px',
        title : '52px',
        details : '17px',
        buttons : '15px'
    },
    
};