import styled from 'styled-components'


export const Form = styled.form`
    display: inline-block;
    width: auto;
`;

export const Input = styled.input`
    width:90%;
    background-color : ${ ({theme}) => theme.colors.white};
    border-color: ${({theme,error}) => error? theme.colors.red : 'rgba(0, 0, 0, 0.1)'};
    border-width : 1px;
    padding: 0.6rem;
    border-radius: 10px;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    font-size: 0.8rem;
    color: ${ ({theme}) => theme.colors.darkGrey};
    &:focus{
        border-color: ${({theme,error}) => error? theme.colors.red : '#eeeeee'};
        outline:none;
    }
`;

export const Table = styled.table`
    margin: 2rem 0rem 0rem 0rem;
    tr{
        height: 3rem;
    }
    td{
        width: 14rem;
        color: ${ ({theme}) => theme.colors.darkGrey};
        font-weight: 899;
        font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
        font-size: 1rem;
        letter-spacing: 0px;
        line-height: 1.2;
    }
`;

export const Select = styled.select`
    background-color : ${ ({theme}) => theme.colors.greyInputs};
    border-color: ${({theme,error}) => error? theme.colors.red : 'rgba(0, 0, 0, 0.1)'};
    border-width : 1px;
    padding: 0.6rem;
    border-radius: 10px;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    font-size: 1rem;
    color: ${ ({theme}) => theme.colors.darkGrey};
    &:focus{
        border-color:#eeeeee;
        outline:none;
    }
    width:99%;
`;

export const Option = styled.option`
    background-color : ${ ({theme}) => theme.colors.greyInputs};
    border-color: rgba(0, 0, 0, 0.1);
    border-width : 1px;
    padding: 0.6rem;
    border-radius: 10px;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    font-size: 1rem;
    color: ${ ({theme}) => theme.colors.darkGrey};
    &:focus{
        border-color:#eeeeee;
        outline:none;
    }
`;

export const Button = styled.button`
    padding:0.3rem 0.8rem;
    margin: 0.3rem;
    text-decoration:none;  
    border-radius: 9px;
    border: 1px solid ${ ({theme,color}) => color==='green'?theme.colors.green : theme.colors.red};
    box-sizing: border-box;
    font-family: ${ ({theme}) => theme.fonts.text}, sans-serif;
    letter-spacing: 0px;
    line-height: 1.2;
    color: ${ ({theme,color}) => color==='green'?theme.colors.green : theme.colors.red};
    font-size: 0.8rem;
    &:focus{
        outline: none;
    }
`;

export const Text = styled.p`
    display: inline-block;
    white-space: pre;
    font-weight: 899;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    color: ${({theme,color}) => color==='orange'? theme.colors.orange : theme.colors.darkGrey};
    font-size: ${ ({theme, size}) => size==='small'? 0.7 :0.9}rem;
    letter-spacing: 0px;
    line-height: 1.2;
`;

export const AuthInfoS = styled.div`
    vertical-align: top;
    display: inline-block;
    border-radius: 1.5rem;
    background-color: #ffffff70;
    padding: 2rem;
    width: auto;
    margin: 2rem 1rem 1rem 4rem;
`;