import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'


const Suppliers = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [suppliers, setSuppliers] = useState([]);
    const [display, setDisplay] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect( ()=>{
        async function fetchData (){
            await firestore.collection("suppliers").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id!=='id'){
                        setSuppliers(suppliers => [...suppliers, {...doc.data(), checked:false}]);
                        setDisplay(display=> [...display, {...doc.data(), checked:false}]);
                    }
                });
            });
            // console.log(suppliers);
            setLoading(false)
        }
        fetchData();
    },[]);

    const newSupplier =()=>{
        props.history.push('/suppliers/new-supplier')
    };

    const merge = async (data) => {
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`suppliers/${prop}`).get();
                const temp = doc.data();
                // console.log(temp);
                refs.push(temp);
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/suppliers/merge',{refs:refs});
    }

    const checked = (e) => {
        console.log(e.target.name, e.target.checked);
        let a = [...suppliers];
        console.log(a);
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        console.log("found index: ", idx)
        a[idx].checked = e.target.checked;
        setSuppliers(suppliers=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }

    const updateSearchResults = (e)=>{ 
        setDisplay(suppliers.filter(supplier => 
            supplier.uid.toString().includes(e.target.value.toLowerCase()) ||
            supplier.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
        console.log("display:", display);
    }

    return(
        <Body2>
            <Title>Suppliers</Title>
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box onSubmit={handleSubmit(merge)}>
                <header>
                    <NewDoc onClick={newSupplier} src={newDoc} /> <SearchBar onChange={updateSearchResults} placeholder="Search for a supplier"/> <Merge type='submit'>Merge</Merge>
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '1%'}}/>
                        <col span="1" style={{width: '20%'}}/>  
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>ID</th>
                            <th>Name</th>
                        </tr>
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.name}</td>
                        </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        // {/* {popup.comingSoon? <ComingSoon close={close}/>:""} */}
    );
}

export default Suppliers;



