import React from "react";
import Title from '../themes/Title.style'
import styled from 'styled-components'
import {firestore} from '../firebase/config'
import Body from '../themes/Body.style'
import firebase from 'firebase'


const Dashboard = (props) => {

    const addDateStamp = async () => {
        await firestore.collection("customer-orders").get().then((querySnapshot) => {
            querySnapshot.forEach(async doc => {
                if(doc.id!=='id'){
                    let date = (await firestore.doc(`/customer-orders/${doc.id}`).get()).data().date
                    var dt1   = parseInt(date.substring(0,2));
                    var mon1  = parseInt(date.substring(3,5));
                    var yr1   = parseInt(date.substring(6,10));
                    var today = new Date(yr1, mon1-1, dt1);
                    firestore.doc(`/customer-orders/${doc.id}`).update({dateStamp: firebase.firestore.Timestamp.fromDate(today)})
                }
            });
        });

    }

    return(
        <Body>
            <Title>Welcome </Title>
            {/* <button onClick={addDateStamp}>add date</button> */}
        </Body>
    );
}

export default Dashboard;