import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route} from "react-router";
import firebase from "firebase";
import {useUser} from '../firebase/UserProvider'
import { Loading } from "../pages/CustomerOrder";

const PrivateRoute = ({component: Component, ...rest}) => {
  let {user} = useUser();
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
      if(user){
          setLoading(false)
      }
    if(!user){
        console.log("user not found")
        setTimeout(()=>{ setLoading(false)},900);
    }
  },[])

  return ( <>
      {loading ? <Loading><div className='dot-spin'/></Loading>  :
    <Route
      {...rest}
      render={(routeProps) =>
        user ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
    }</>);
};

export default PrivateRoute;
