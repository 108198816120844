// search inspired from : https://levelup.gitconnected.com/building-a-simple-dynamic-search-bar-in-react-js-f1659d64dfae

import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import ComingSoon from '../components/ComingSoon'
import {Buttons} from '../styles/CustomerOrder.style'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'

const Customers = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [customers, setCustomers] = useState([]);
    const [display, setDisplay] = useState([]);
    const [popup, setPopup] = useState({comingSoon:false});
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true)

    useEffect( ()=>{
        async function fetchData (){
            await firestore.collection("customers").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    setCustomers(customers => [...customers, {...doc.data(), checked:false}]);
                    setDisplay(display=> [...display, {...doc.data(), checked:false}]);
                });
                setLoading(false)
            });
            
        }
        fetchData();
        // console.log(customers);
    },[]);

    const newCustomer =()=>{
        props.history.push('/customers/new-customer')
    };

    const merge = async (data) => {
        // console.log(data);
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`customers/${prop}`).get();
                const temp = doc.data();
                // console.log(temp);
                refs.push(temp);
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/customers/merge',{refs:refs});
        // setPopup({...popup, comingSoon:true})
    }
    const close = ()=>{setPopup({...popup, comingSoon:false})}

    const checked = (e) => {
        // console.log(e.target.name, e.target.checked);
        let a = [...customers];
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        a[idx].checked = e.target.checked;
        setCustomers(customers=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }


    const updateSearchResults = (e)=>{ 
        setDisplay(customers.filter(customer => 
            customer.uid.toString().includes(e.target.value.toLowerCase()) ||
            customer.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
            customer.lastname.toLowerCase().includes(e.target.value.toLowerCase())||
            customer.company.toLowerCase().includes(e.target.value.toLowerCase())||
            customer.address.toLowerCase().includes(e.target.value.toLowerCase())||
            customer.credit_auth.toLowerCase().includes(e.target.value.toLowerCase())||
            customer.credit_limit.toString().includes(e.target.value.toLowerCase())||
            // customer.pending.toString().includes(e.target.value.toLowerCase())||
            customer.wallet.toString().includes(e.target.value.toLowerCase())
        ));
        console.log("display:", display);
    }

    const viewPassbook = (id) => {
        props.history.push(`/payments/passbook/${id}`, {uid:id})
    };

    return(
        <>
        <Body2>
            <Title>Customers</Title>
            {loading? <Loading><div class='dot-spin'/></Loading> : <Box onSubmit={handleSubmit(merge)}>
                <header>
                <NewDoc src={newDoc} onClick={newCustomer}/> <SearchBar onChange={updateSearchResults} placeholder="Search for a Customer"/>  <Merge type='submit'>Merge</Merge>
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '4%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '15%'}}/>
                        <col span="1" style={{width: '20%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '8%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '10%'}}/>
                        <col span="1" style={{width: '8%'}}/>
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>Customer ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Company Name</th>
                            <th>Address</th>
                            <th>Credit Authorisation</th>
                            <th>Credit Limit</th>
                            {/* <th>Total Pending</th> */}
                            <th>Balance</th>
                            <th>Payments</th>
                        </tr>
                        {/* {console.log(display)} */}
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.company}</td>
                            <td>{item.address}</td>
                            <td>{item.credit_auth}</td>
                            <td>{item.credit_limit}</td>
                            {/* <td>{item.pending}</td> */}
                            <td>{item.wallet}</td>
                            <td><Buttons color='orange' onClick={()=> {viewPassbook(item.uid)}}>View</Buttons></td>
                            </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        {popup.comingSoon? <ComingSoon close={close}/>:""}
        </>
    );
}

export default Customers;



