import React from 'react'
import styled from 'styled-components'

const ComingSoon =(props) => {
    return (
        <PopupS>
            <PopupContentS>
                <h1>Coming Soon</h1>
                <Close onClick={props.close}>x</Close>
            </PopupContentS>
        </PopupS>
    );
}

const PopupS = styled.div`
    width: 100vw;
    height: 100vh;
    background: #00000050;
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
`;

const PopupContentS = styled.div`
    position: absolute;
    background-color : ${({theme}) => theme.colors.white};
    width: 20%;
    height: 20%;
    margin-top: auto;
    margin-bottom:auto;
    top:0;
    bottom:0;
    margin:auto;
    left:0;
    right: 0;
    border-radius: 1rem;
    padding: 1rem 2rem;
    overflow-y: auto;
    overflow-wrap: break-word;
    
`;

const Close = styled.button`
    position: absolute;
    top:1rem;
    right:1rem;
    background: none;
    border: none;
    font-size: 1rem;
`;


export default ComingSoon;