import React, { useContext, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import {Title, Button, StaffLoginS, Input, SeaBlue} from '../styles/StaffLogin.style'
import {captcha, setPhone, submitPhoneNumberAuth, submitPhoneNumberAuthCode} from '../firebase/auth'
import 'firebase/auth'
import firebase from 'firebase/app'
import {app, firestore} from '../firebase/config'
import {useUser} from '../firebase/UserProvider'
import styled from "styled-components";
import model1 from '../assets/login.png'
import model2 from '../assets/login2.png'


function StaffLogin(props){
    const {register, handleSubmit, reset} = useForm();
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    // const [user, setUser] = useState();
    const {user, setUser} = useUser();
    // const [stateUser, setStateUser] = useState();
    // const [phone, setPhone] = useState(0);

    // useEffect(() => {
    //     console.log('useEffect');
        
    // }
    // , []);

    useEffect(()=> {
        // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        console.log("Use effect called");
       if(user){
           console.log("user in useEffect:", user)
           props.history.push(`/dashboard/${user.uid}`)
       }
    },[user])

    const onSubmit =  async (data) => {
        console.log(data)
        let newUser;
        let phone = '+91' + data.phoneNum.toString();
        const doc = await firestore.doc(`/staff/${phone}`).get();
        // const auths = doc.data().uid;
        if(!doc.exists){
            props.history.replace('/staff-login/uh-oh')
        }
        await captcha();
        await submitPhoneNumberAuth(phone);
    };

    const onSubmitOTP = async (data, e) => {
        e.preventDefault();
        console.log("submitting otp",data);
        try{
            await submitPhoneNumberAuthCode(data.otp, e);
        }
        catch(error){
            console.log(error);
        }
    };

    const submitPhoneNumberAuth = async (phone)=> {
        console.log("phone", phone);
      var appVerifier = window.recaptchaVerifier;
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
            app
            .signInWithPhoneNumber(phone, appVerifier)
            .then(function(confirmationResult) {
            window.confirmationResult = confirmationResult;
            setOtpSent(true);
            }).then((response) => {
            appVerifier.clear();
            })
            .catch(function(error) {
            console.log(error);
            });
        })
    }

    const captcha =async () =>{
        console.log('captcha');
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in",
        {
          size: 'invisible',
          callback: function(response) {
            // submitPhoneNumberAuth();
          }
        }
      );
    }

    const  submitPhoneNumberAuthCode = async (code,e) => {
        e.preventDefault();
        console.log(code);
        window.confirmationResult.confirm(code)
        .then((result) => {
            console.log(result);
            setUser(user => result.user);
            // setStateUser(user);
        }).
        catch(function(error) {
            console.log(error);
        });
    }
    


    return (
        <StaffLoginS>

            <SeaBlue/>
            <Sitting src={model1}/>
            <Standing src={model2}/>
            <div className = "info">
                <Title color = 'black'>Welcome</Title>
                <Title>Staff</Title>
                
                    {!otpSent ?
                    <form onSubmit = {handleSubmit(onSubmit)}>
                        <Input key={1} type = 'tel' {...register('phoneNum', { required: true })} placeholder = "Phone Number" id ='a' /> 
                        <Button key={2} type= 'submit' id ="sign-in"> Login </Button> 
                    </form> :
                    <form onSubmit = {handleSubmit(onSubmitOTP)}>
                        <Input key = {3} type = 'number' {...register('otp', { required: true })} placeholder = "Enter OTP" id = 'b'/> 
                        <Button key = {4} type = 'submit' id = 'otp'>Verify</Button> 
                    </form>}
                
            </div>
        </StaffLoginS>
    );
}

const Sitting=styled.img`
    position:absolute;
    z-index:2;
    bottom:3rem;
    left:1.5rem;
    width:20rem;
`;

const Standing=styled.img`
    position:absolute;
    z-index:2;
    bottom:3rem;
    left:13.5rem;
    width:22rem;
`;

export default StaffLogin