import React from "react";

import NewArchitect from "./NewArchitect";


const EditArchitect= (props) =>{
    const user = props.history.location.state.user;
    const userProfile = {
        uid: user.uid,
        firstname: user.firstname,
        lastname: user.lastname,
        address: user.address,
        company: user.company,
        dis_slab_1: user.dis_slab_1,
        dis_slab_2 : user.dis_slab_2
    };
    return <NewArchitect {...props} editing={true} user={userProfile}/>

}

export default EditArchitect;