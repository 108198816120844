import React from "react";

import NewCustomer from "./NewCustomer";


const EditCustomer= (props) =>{
    const user = props.history.location.state.user;
    const userProfile = {
        uid: user.uid,
        firstname: user.firstname,
        lastname: user.lastname,
        address: user.address,
        company: user.company,
        credit_auth: user.credit_auth,
        credit_limit : user.credit_limit? parseInt(user.credit_limit):0
    };
    return <NewCustomer {...props} editing={true} user={userProfile}/>

}

export default EditCustomer;