import styled from 'styled-components';

export const Title = styled.h1`
    color: ${({color, theme}) => color === 'black' ? theme.colors.darkGrey : theme.colors.seaBlue};
    // line-height: -10;
    margin :-2rem 0 1rem 0;
    // height : 2rem;
    font-weight: 700;
    font-style: normal;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    letter-spacing: -2.0px;
    font-size: 4rem;
`;

export const Input = styled.input`
    display : block;
    background-color: ${ ({theme}) => theme.colors.white};
    padding: 0.6rem;
    margin: -1rem 0rem 2rem 0rem;
    border-radius : 0.6rem;
    color: ${({color, theme}) => color === 'black' ? theme.colors.darkGrey : theme.colors.seaBlue};
    font-style: normal;
    font-family: ${ ({theme}) => theme.fonts.text}, sans-serif;
    font-size: 1rem;
    width: 23.4rem;
    border: none;
    &:focus{
        outline: none;
    }
`;

export const Button = styled.button`
    margin: -1rem 0rem 2rem 0rem;
    border : none;
    // margin : -6rem 0 8rem 0;
    display : block ;
    text-decoration : none;
    width: 24.8rem;
    height: 42px;
    box-shadow: 1px 1px 4px 1px #777777;
    background-color: ${ ({theme}) => theme.colors.seaBlue};
    border-radius: 12px;
    line-height: 1.2;
    font-size: 21px;
    font-weight: 599;
    letter-spacing: 0.2px;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    color : ${ ({theme}) => theme.colors.white};
    &:focus{
        outline: none;
        box-shadow: none;
    }
`;

export const SeaBlue = styled.div`
    height : 100vh;
    overflow : hidden;
    width : 405px;
    position : absolute;
    background-color: ${ ({theme}) => theme.colors.seaBlue};
    top : 0;
    right : 0;
    // z-index : -1;
`;

export const StaffLoginS = styled.div`
    height : 100vh;
    width : 100vw;
    overflow: hidden;
    position : relative;
    background-color : ${ ({theme}) => theme.colors.darkBlue};
    
    .info{
        padding: 5rem 3rem 0 3rem;
        position : absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right : 10rem;
        background-color: ${ ({theme}) => theme.colors.grey1};
        height: 19rem;
        border-radius : 3rem;
        box-shadow: 0px 0px 28px -3px rgba(85, 85, 85, 0.94);
    }
`;
