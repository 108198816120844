import React from 'react'
import styled from 'styled-components'
import Body from '../themes/Body.style'


export const Box = styled.div`
    width: 100%;
    // position:relative;
    overflow-y:auto;
    flex-grow:1;
    background-color: ${ ({theme}) => theme.colors.white};
    border-radius: 1rem;
    header{
        padding: 1rem;
        position: sticky;
        // top: 0;
        // display: block;
    }
    // max-height: 40rem;
    position:relative;
`;


export const Buttons = styled.button`
    padding:0.2rem 0.7rem;
    margin: 0.3rem;
    text-decoration:none;  
    border-radius: 9px;
    border: 1px solid ${ ({theme,color}) => color=="red"?theme.colors.red:
                                                color=="green"?theme.colors.green:
                                                    color=='amber'?theme.colors.amber:
                                                        color=='grey'?theme.colors.darkGrey:
                                                            theme.colors.orange};
    box-sizing: border-box;
    font-family: ${ ({theme}) => theme.fonts.text}, sans-serif;
    letter-spacing: 0px;
    line-height: 1.2;
    color: ${ ({theme,color}) => color=="red"?theme.colors.red:
                                    color=="green"?theme.colors.green:
                                        color=='amber'?theme.colors.amber:
                                            color=='grey'?theme.colors.darkGrey:
                                                theme.colors.orange};
    background:none;
    font-size: 0.7rem;
    &:focus{
        outline: none;
    }
    float:right;
`;

export const Box2 = styled.div`
    display: flex;
    margin:0 0 1rem 0;
`;

export const CoInfo = styled.table`
    border-collapse:collapse;
    position: relative;
    // display:table;
    overflow:auto;
    width:${({width})=> width?width:27}%;
    tbody{
        overflow-y:auto;
        max-height: 37rem;
    }
    tr{
        // width: 100%;
        // display: table;
        padding:0.4rem;
        height:1.5rem;
        // border-color: #99999950;
        border-style: solid;
        border-top-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        font-size:0.7rem;
        font-family:${({theme})=> theme.colors.inter};
        // transition: linear  0.15s;
        // transition-delay:0.1s;
    }
    td{
        border: none;
        overflow:auto;
    }
    th{
        border:none;
        text-align: left;
    }
`;

export const Cust = styled.div`
    background-color: ${({theme})=> theme.colors.white}62;
    border-radius: 24px;
    // display:flex;
    width:35%;
    padding:0.8rem 0.8rem 0.8rem 1.1rem;
    margin: 0 0 0 ${({margin})=> margin}px;
    position:relative;
`;

export const CustTables = styled.div`
    display:flex;
    font-size:0.65rem;
    width:100%;
    ${({margin}) => margin?margin:'0'}
`;

export const CustInfo = styled.table`
    // float:left;
    border-collapse:collapse;
    position: relative;
    // display:table;
    overflow:auto;
    width:50%;
    tbody{
        overflow-y:auto;
        max-height: 37rem;
    }
    tr{
        // width: 100%;
        // display: table;
        padding:0.4rem;
        height:1.3rem;
        // border-color: #99999950;
        border-style: solid;
        border-top-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        
        font-family:${({theme})=> theme.colors.inter};
        // transition: linear  0.15s;
        // transition-delay:0.1s;
    }
    td{
        border: none;
        overflow:auto;
    }
    th{
        border:none;
        text-align: left;
    }
`;

export const Input = styled.input`
width:80%;
height:80%;
background-color : ${ ({theme}) => theme.colors.white};
border-color: ${({theme,error}) => error? theme.colors.red : 'rgba(0, 0, 0, 0.1)'};
border-width : 1px;
padding: 0.2rem;
border-radius: 10px;
font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
font-size: 0.8rem;
color: ${ ({theme}) => theme.colors.darkGrey};
&:focus{
    border-color: ${({theme,error}) => error? theme.colors.red : '#eeeeee'};
    outline:none;
}
`;