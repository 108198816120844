import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import ComingSoon from '../components/ComingSoon'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'


const Architects = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [architects, setArchitects] = useState([]);
    const [display, setDisplay] = useState([]);
    const [popup, setPopup] = useState({comingSoon:false});
    const [loading, setLoading] = useState(true)

    useEffect( ()=>{
        async function fetchData (){
            await firestore.collection("architects").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    setArchitects(architects => [...architects, {...doc.data(), checked:false}]);
                    setDisplay(display=> [...display, {...doc.data(), checked:false}]);
                });
            });
            setLoading(false)
            // console.log(architects);
        }
        fetchData();
    },[]);

    const newArchitect =()=>{
        props.history.push('/architects/new-architect')
    };

    const merge = async (data) => {
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`architects/${prop}`).get();
                const temp = doc.data();
                // console.log(temp);
                refs.push(temp);
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/architects/merge',{refs:refs});
    }
    const close = ()=>{setPopup({...popup, comingSoon:false})}

    const checked = (e) => {
        console.log(e.target.name, e.target.checked);
        let a = [...architects];
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        a[idx].checked = e.target.checked;
        setArchitects(architects=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }

    const updateSearchResults = (e)=>{ 
        setDisplay(architects.filter(architect => 
            architect.uid.toString().includes(e.target.value.toLowerCase()) ||
            architect.firstname.toLowerCase().includes(e.target.value.toLowerCase()) ||
            architect.lastname.toLowerCase().includes(e.target.value.toLowerCase())||
            architect.company.toLowerCase().includes(e.target.value.toLowerCase())||
            architect.address.toLowerCase().includes(e.target.value.toLowerCase())||
            architect.dis_slab_1.toString().includes(e.target.value.toLowerCase())||
            architect.dis_slab_2.toString().includes(e.target.value.toLowerCase())
        ));
        console.log("display:", display);
    }

    return(
        <>
        <Body2>
            <Title>Architects</Title>
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box onSubmit={handleSubmit(merge)}>
                <header>
                    <NewDoc onClick={newArchitect} src={newDoc}/> <SearchBar onChange={updateSearchResults} placeholder="Search for an Architect"/> <Merge type='submit'>Merge</Merge>
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '4%'}}/>
                        <col span="1" style={{width: '9%'}}/>  
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '20%'}}/>
                        <col span="1" style={{width: '29%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        {/* <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/> */}
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>Architect ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Company Name</th>
                            <th>Address</th>
                            <th>Discount Slab 1</th>
                            <th>Discount Slab 2</th>
                        </tr>
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.company}</td>
                            <td>{item.address}</td>
                            <td>{item.dis_slab_1}</td>
                            <td>{item.dis_slab_2}</td>
                            </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        {popup.comingSoon? <ComingSoon close={close}/>:""}
        </>
    );
}

export default Architects;



