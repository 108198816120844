import React from "react";
import {Title, Button, StaffLoginS, Input, SeaBlue} from '../styles/StaffLogin.style'


const  Unauth = (props)=>{
    return (
        <StaffLoginS>
            <SeaBlue/>
            <div className = "info">
                <Title color = 'black'>Uh Oh!</Title>
                <Title color = 'black'>User</Title>
                <Title>Unauthorised</Title>
            </div>
        </StaffLoginS>
    );
}

export default Unauth;