import React from 'react'
import styled from 'styled-components'
import Body from '../themes/Body.style'
import { SearchBar } from './Customers.style';

export const Table = styled.table`
    z-index=0;
    border-collapse:collapse;
    position: relative;
    // display:table;
    width:100%;
    overflow:auto;
    max-height: 37rem;
    tbody{
        // display: table;
        overflow-y:auto;
        max-height: 37rem;
    }
    tr{
        width: 100%;
        // display: table;
        padding:0.7rem;
        height:2.6rem;
        border-color: #99999950;
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        font-size:0.8rem;
        font-family:${({theme})=> theme.colors.inter};
        transition: linear  0.15s;
        // transition-delay:0.1s;
    }
    // tr:hover{
    //     box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
    // }
    td{
        border: none;
        overflow:auto;
        text-align: center;
    }
    #head{
        background-color: ${({theme})=> theme.colors.orange};
        position:sticky;
        top:0;
    }
    th{
        border:none;
        text-align: center;
    }
`;


export const CoInfo = styled(Table)`
    box-shadow: 0 0 2px 1000px white;
    border-radius: 1rem,
    border-collapse:collapse;
    margin: 10;
    position: relative;
    // display:table;
    overflow:auto;
    
    width:100%;
    tbody{
        overflow-y:auto;
        // max-height: 37rem;
    }
    tr{
        // width: 100%;
        // display: table;
        padding:0.4rem;
        height:1.5rem;
        // border-color: #99999950;
        border-style: solid;
        border-top-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        font-size:0.7rem;
        font-family:${({theme})=> theme.colors.inter};
        // transition: linear  0.15s;
        // transition-delay:0.1s;
    }
    td{
        border: none;
        overflow:auto;
        padding: 0.3rem
    }
    th{
        border:none;
        text-align: left;
    }
`;

export const Box = styled.div`
    width: 100%;
    // position:relative;
    overflow-y:auto;
    flex-grow:1;
    background-color: ${ ({theme}) => theme.colors.white};
    border-radius: 1rem;
    header{
        padding: 1rem;
        position: sticky;
        // top: 0;
        // display: block;
    }
    // max-height: 40rem;
`;

export const SearchBars = styled(SearchBar)`
    width: 17 rem;
`
