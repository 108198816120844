import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'
import editIcon from '../assets/edit.png'
import styled from 'styled-components'
import {Input} from '../styles/NewCustomer.style'
import saveIcon from '../assets/save.png'
import {Buttons} from '../styles/CustomerOrder.style'



const ProductCategories = (props) => {
    const {handleSubmit, register, setValue, formState: { errors }} = useForm();
    const [products, setProducts] = useState([]);
    const [display, setDisplay] = useState([]);
    const [loading, setLoading] = useState(true)
    const [edittingMode, setEdittingMode] = useState(false)
    const [currentlyEdittingId, setCurrentlyEdittingId] = useState();
    const [dis, setDis] = useState(0);


    const add_max_discount_field = async () => {
        await firestore.collection("product-categories").get().then((querySnapshot) => {
            querySnapshot.forEach(doc => {
                if(doc.id!=='id'){
                    firestore.doc(`/product-categories/${doc.id}`).update({max_discount:0})
                }
            });
        });
    };

    useEffect( ()=>{
        async function fetchData (){
            await firestore.collection("product-categories").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id!=='id'){
                        setProducts(products => [...products, {...doc.data(), checked:false, editting:false}]);
                        setDisplay(display=> [...display, {...doc.data(), checked:false, editting:false}]);
                    }
                });
            });
            // console.log(suppliers);
            setLoading(false)
        }
        fetchData();
    },[]);

    const newProduct =()=>{
        props.history.push('/product-categories/new-product-category')
    };

    const merge = async (data) => {
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`product-categories/${prop}`).get();
                if(doc.exists)
                {const temp = doc.data();
                // console.log(temp);
                refs.push(temp);}
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/product-categories/merge',{refs:refs});
    }

    const checked = (e) => {
        console.log(e.target.name, e.target.checked);
        let a = [...products];
        console.log(a);
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        console.log("found index: ", idx)
        a[idx].checked = e.target.checked;
        setProducts(products=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }

    const updateSearchResults = (e)=>{ 
        setDisplay(products.filter(product => 
            product.uid.toString().includes(e.target.value.toLowerCase()) ||
            product.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
        console.log("display:", display);
    }

    const enableEdit = (uid) => {
        setDisplay(display=>display.map((item)=> item.uid==uid?{...item,editting:true}:item));
        setProducts(products=>products.map((item)=> item.uid==uid?{...item,editting:true}:item));
        setEdittingMode(true)
        setCurrentlyEdittingId(uid);
    };

    const cancel = (uid) => {
        setDisplay(display=>display.map((item)=> item.uid==uid?{...item,editting:false}:item));
        setProducts(products=>products.map((item)=> item.uid==uid?{...item,editting:false}:item));
        setEdittingMode(false)
    };

    const save = (data) => {
        console.log(data)
        let nameId = `${currentlyEdittingId}_name`
        let disId = `${currentlyEdittingId}_dis`
        const name = data[nameId]
        const dis = parseInt(data[disId])
        setDisplay(display=>display.map((item)=> item.uid==currentlyEdittingId?{...item,editting:false,  max_discount:dis, name:name}:item));
        setProducts(products=>products.map((item)=> item.uid==currentlyEdittingId?{...item,editting:false,  max_discount:dis, name:name}:item));
        firestore.doc(`/product-categories/${currentlyEdittingId}`).update({name:name, max_discount:dis})
        setEdittingMode(false)
    }

    // const handleDisChange = (...args) => {
    //     // console.log(args[0].target.value)
    //     setDis(parseInt(args[0].target.value))
    //     console.log(args[0].target.value)
    // }


    return(
        <Body2>
            <Title>Product Categories</Title>
            {/* <button onClick={add_max_discount_field}>add max discount field</button> */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box>
                <header>
                    <NewDoc src={newDoc} onClick={newProduct}/> <SearchBar onChange={updateSearchResults} placeholder="Search for a Product Category"/> <Merge type='submit' onClick={handleSubmit(merge)}>Merge</Merge>
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '5%'}}/>
                        <col span="1" style={{width: '20%'}}/>  
                        <col span="1" style={{width: '20%'}}/>  
                        <col span="1" style={{width: '55%'}}/>  
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>ID</th>
                            <th>Product Category Name</th>
                            <th>Maximum Discount (%)</th>
                            <th>Edit/Save</th>
                            <th></th>
                        </tr>
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.editting?
                                    <Input 
                                    error={errors[`${item.uid}_name`] && errors[`${item.uid}_name`].type == "required"} 
                                    key={`${item.uid}input`} 
                                    type='text' {...register(`${item.uid}_name`, { required: true })} 
                                    placeholder = "Product Category Name"
                                    defaultValue={item.name}
                                    />
                                :item.name}</td>
                            <td>{item.editting?
                                    <Input 
                                    error={(errors[`${item.uid}_dis`] && errors[`${item.uid}_dis`].type == "required") } 
                                    key={`${item.uid}_dis`} 
                                    type="number" {...register(`${item.uid}_dis`, { required: true })} 
                                    // onChange={handleDisChange}
                                    placeholder = "Maximum Discount"
                                    defaultValue={item.max_discount}
                                    />
                                :item.max_discount}</td>
                            <td>{item.editting?<SaveButton  onClick={handleSubmit(save)}><SaveIcon src={saveIcon}/></SaveButton>:edittingMode?<></>:<EditIcon onClick={()=>enableEdit(item.uid)} src={editIcon}/>}</td>
                            <td>{item.editting?<Buttons onClick={()=> cancel(item.uid)}>x</Buttons>:<></>}</td>
                        </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        // {/* {popup.comingSoon? <ComingSoon close={close}/>:""} */}
    );
}

const EditIcon = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.3rem;
`;
const SaveButton = styled.button`
    margin:0;
    background:none;
    border:none;
    padding:0;
`;


const SaveIcon = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.3rem;
`;


export default ProductCategories;



