import firebase from 'firebase/app'
import 'firebase/auth'
// import { useContext } from 'react';
// import {UserContext} from './UserProvider'
import 'firebase/firestore'


firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
});


export const app = firebase.auth();
console.log(firebase.app().options);

export const firestore = firebase.firestore();
