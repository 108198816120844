import styled from 'styled-components'

const Body = styled.div`
    height:93vh;
    position: fixed;
    top: 0;
    bottom:0;
    margin-top: auto;
    margin-bottom: auto;
    width : calc(${window.innerWidth/16}rem - 19.5rem);
    left: 18rem;
    padding-top: 1.5rem;
    // display: flex;
    // float:left;
    // background: black;
`;

export default Body