import { Link } from 'react-router-dom';
import React from 'react';

import { LandingPageS } from '../styles/LandingPage.style';
import { Button } from '../styles/LandingPage.style';
import { Title } from '../styles/LandingPage.style';

import office from '../assets/palantir_home.png'
import styled from 'styled-components';


function LandingPage(props){
    return (
        <LandingPageS>
            <div className = "info">
            <Title> Palantir</Title>
            <Link to='/staff-login' style={{ textDecoration: 'none' }}> <Button>Staff Login</Button> </Link>
            <Link to='/admin-login' style={{ textDecoration: 'none' }}><Button style={{top:'3rem'}}>Admin Login</Button></Link>
        </div>
        <Office src={office}/>
        </ LandingPageS>
    );
}

const Office = styled.img`
    position: absolute;
    // height:1192px;
    width: 1300px;
    top: 50px;
    left: 100px;
    z-index:0;
`



export default LandingPage;