import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import {Title, Button, StaffLoginS, Input, SeaBlue} from '../styles/StaffLogin.style'
import adminIcon from '../assets/admin_login.png'
import styled from "styled-components";

function AdminLogin(props){
    const {register, handleSubmit, reset} = useForm();
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);

    const onSubmit = data => {
        console.log(data)
    };

    return (
        <StaffLoginS>
        <SeaBlue/>
        <Admin src={adminIcon}/>
        <div className = "info">
            <Title color = 'black'>Hello</Title>
            <Title>Admin</Title>
            <form onSubmit = {handleSubmit(onSubmit)}>
                {!otpSent ?
                <Input type = 'tel' {...register('phoneNum', { required: true })} placeholder = "Phone Number" /> :
                <Input type = 'number' {...register('otp', { required: true })} placeholder = "Enter OTP"/> }
                {!otpSent ? <Button id ="sign-in"> Login </Button> : <Button id = "submit">Verify</Button> }
            </form>
        </div>
    </StaffLoginS>
    );
}
const Admin= styled.img`
    position:absolute;
    z-index:2;
    bottom:0rem;
    left:4.5rem;
    width:25rem;
`;

export default AdminLogin