import React, { useEffect, useState, useContext, createContext, useReducer } from 'react';
import firebase from 'firebase/app';

// const initialState = {user: null};
export const UserContext = createContext();

// export function userReducer(state,action){
//     switch (action.type) {
//         case 'setUser': {
//             // console.log("setting user:", action.payload);

//             let newState =  {user : colorsassign({}, action.payload)};
//             console.log(newState);
//             return newState;
//         }
//         case 'logout': {
//             console.log("logout");
//             return {user:null};
//         }
//         default: {
//             return state;
//         }
//     }
// }

export function UserProvider({children}){
    const [user, setUser] = useState();
    const value = {user, setUser};

    useEffect(() => {
        firebase.auth().onAuthStateChanged((thisUser) => {    
            if(thisUser){
                setUser(thisUser)
                console.log("user found")
            }
        });
    
        // return () => unsubscribe();
      },[]);

      
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
    }
    // console.log("context:", context);
    return context;
}
    
// export {UserProvider, useUser, UserContext}