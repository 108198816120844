import React from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {useUser} from '../firebase/UserProvider'
import {useLocation} from 'react-router-dom'



function Header(props){

    // console.log("Hi Im Header");
    const {user} = useUser();
    let location = useLocation();
    console.log(location);
    if (location.pathname === '/' || location.pathname==='/staff-login' || location.pathname==='admin-login'){
        return "";
    }
    let a = location.pathname;
    return(
        <HeaderS>
            <Palantir> Palantir </Palantir>
            <Link to ={`/dashboard/`} ><Button loc={a} name={'dashboard'} >Dashboard</Button></Link>
            <Link to ='/customer-orders' ><Button loc={a} name={'customer-orders'} >Customer Orders</Button></Link>
            <Link to ='/customer-orders-master-data' ><Button loc={a} name={'customer-orders-master-data'} >Customer Orders Master</Button></Link>
            <Link to ='/customers' ><Button loc={a} name={'customers'} >Customers</Button></Link>
            <Link to ='/architects' ><Button loc={a} name={'architects'} >Architects</Button></Link>
            <Link to ='/suppliers' ><Button loc={a} name={'suppliers'} >Suppliers</Button></Link>
            <Link to ='/product-categories' ><Button loc={a} name={'product-categories'} >Products</Button></Link>
            <Link to ='/ISRs' ><Button loc={a} name={'ISRs'} >ISRs</Button></Link>
            <Link to ='/OSRs' ><Button loc={a} name={'OSRs'} >OSRs</Button></Link>
            <Link to ='/payments' ><Button loc={a} name={'payments'} >Payments</Button></Link>
        </HeaderS>
    );
}

const Palantir = styled.h1`
    width: auto; /* 151px */
    height: auto; /* 44px */
    overflow: visible;
    white-space: pre;
    line-height: 1.2;
    letter-spacing: 4.8px;
    font-size: 1.7rem;
    color: rgba(149, 165, 166, 0.72);
    font-weight: 200;
    font-style: normal;
    font-family: ${({theme}) => theme.fonts.titles};
`;


const HeaderS = styled.nav`
    // z-index:10;
    height : 97vh;
    width: 15rem;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom:0;
    margin-top: auto;
    border-radius: 30px;
    box-shadow: 0px 0px 6px 4px #95a5a6;
    margin-bottom: auto;
    left: 1.5vh;
    display : flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    background-color: ${ ({theme}) => theme.colors.darkBlue};
    color: ${ ({theme}) => theme.colors.white};
`;

const Button = styled.button`
    width : 13rem;
    height: 3.56rem;
    border-radius: 1rem;
    margin: 0.1rem;
    letter-spacing: 0px;
    line-height: 1.2;
    color: ${({theme}) => theme.colors.white};
    font-weight: 200;
    font-style: normal;
    font-family: ${({theme}) => theme.fonts.header}, serif;
    font-size: 1.2em;
    background: none;
    border: none;
    :focus{
        background-color: ${ ({theme}) => theme.colors.seaBlue};
        outline: none;
    }
    background-color: ${ ({theme, loc, name}) => loc==(name)?theme.colors.seaBlue:'none'};

`;
export default Header;