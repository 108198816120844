import React from 'react'
import styled from 'styled-components'
import Body from '../themes/Body.style'

export const New = styled.button`
    margin:0 2rem;
`;

export const SearchBar = styled.input`
    background-color:${ ({theme}) => theme.colors.greyInputs};
    font-family:${ ({theme}) => theme.fonts.text};
    font-size: 0.8rem;
    text-align:left;
    color:#333;
    border: none;
    border-radius: 5px;
    &:focus{
        outline: none;
        border:1px solid ${ ({theme})=> theme.colors.darkGrey}50;
    }
    padding: 0.5rem;
    width: 40rem;
    margin: auto 0;
`;

export const SearchButton = styled.button`
    margin: 0 1rem;
`;

export const Box = styled.form`
    width: 100%;
    // position:relative;
    overflow-y:auto;
    flex-grow:1;
    background-color: ${ ({theme}) => theme.colors.white};
    border-radius: 1rem;
    header{
        padding: 1rem;
        position: sticky;
        // top: 0;
        // display: block;
    }
    // max-height: 40rem;
`;

export const Body2 = styled(Body)`
    display: flex;
    // width: 100%;
    flex-direction: column;
`;

export const Merge = styled.button`
    padding:0.3rem 0.8rem;
    margin: 0.3rem;
    text-decoration:none;  
    border-radius: 9px;
    border: 1px solid ${ ({theme}) => theme.colors.orange};
    box-sizing: border-box;
    font-family: ${ ({theme}) => theme.fonts.text}, sans-serif;
    letter-spacing: 0px;
    line-height: 1.2;
    color: ${ ({theme}) => theme.colors.orange};
    background:none;
    font-size: 0.8rem;
    &:focus{
        outline: none;
    }
    float:right;
`;

export const Table = styled.table`
    z-index=0;
    border-collapse:collapse;
    position: relative;
    // display:table;
    width:100%;
    overflow:auto;
    max-height: 37rem;
    tbody{
        // display: table;
        overflow-y:auto;
        max-height: 37rem;
    }
    tr{
        width: 100%;
        // display: table;
        padding:0.7rem;
        height:2.6rem;
        border-color: #99999950;
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        font-size:0.8rem;
        font-family:${({theme})=> theme.colors.inter};
        transition: linear  0.15s;
        // transition-delay:0.1s;
    }
    tr:hover{
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
    }
    td{
        border: none;
        overflow:auto;
        text-align: center;
    }
    #head{
        background-color: ${({theme})=> theme.colors.orange};
        position:sticky;
        top:0;
    }
    th{
        border:none;
        text-align: center;
    }
`;