import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'

const ISR = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [isrs, setIsrs] = useState([]);
    const [display, setDisplay] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect( ()=>{
        async function fetchData (){
            await firestore.collection("ISRs").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    setIsrs(isrs => [...isrs, {...doc.data(), checked:false}]);
                    setDisplay(display=> [...display, {...doc.data(), checked:false}]);
                });
            });
            setLoading(false)
            // console.log(suppliers);
        }
        fetchData();
    },[]);

    const newIsr =()=>{
        props.history.push('/ISRs/new-isr')
    };

    const merge = async (data) => {
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`ISRs/${prop}`).get();
                const temp = doc.data();
                // console.log(temp);
                refs.push(temp);
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/ISRs/merge',{refs:refs});
    }

    const checked = (e) => {
        console.log(e.target.name, e.target.checked);
        let a = [...isrs];
        console.log(a);
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        console.log("found index: ", idx)
        a[idx].checked = e.target.checked;
        setIsrs(isrs=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }

    const updateSearchResults = (e)=>{ 
        setDisplay(isrs.filter(isr => 
            isr.uid.toString().includes(e.target.value.toLowerCase()) ||
            isr.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
        console.log("display:", display);
    }

    return(
        <Body2>
            <Title>ISRs</Title>
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box onSubmit={handleSubmit(merge)}>
                <header>
                    <NewDoc src={newDoc} onClick={newIsr}/> <SearchBar onChange={updateSearchResults} placeholder="Search for an ISR"/> <Merge type='submit'>Merge</Merge>
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '10%'}}/>
                        <col span="1" style={{width: '20%'}}/>  
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>ID</th>
                            <th>Name</th>
                        </tr>
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.name}</td>
                        </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        // {/* {popup.comingSoon? <ComingSoon close={close}/>:""} */}
    );
}

export default ISR;



