import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Merge, Table} from '../styles/Customers.style'
import {Box, CustInfo, CustTables} from '../styles/CustomerOrder.style'
import {firestore} from '../firebase/config'
import styled from 'styled-components'
import ExistPopup from '../components/ExistPopup'
import {Buttons, CoInfo, Box2, Cust} from '../styles/CustomerOrder.style'
import {Text} from '../styles/NewCustomer.style'
import { CoDetails, CustDetails , ItemForm} from '../components/CustomerOrderHelpers'
import {Input,Button, Option,Select} from '../styles/NewCustomer.style'
import {useUser} from '../firebase/UserProvider'
import exportPDF from '../components/toPdf'
import firebase from 'firebase'
import editIcon from '../assets/edit.png'




const CO = (props) =>{
    const {handleSubmit, register, setValue, watch,  formState: { errors }} = useForm();
    const [metadata, setMetadata] = useState(props.history.location.state.co);
    const [co,setCo] = useState([]);
    // const [display, setDisplay] = useState([]);
    const [history, setHistory] = useState(false);
    const [content, setContent] = useState({header: "History", text: ""});

    const [valuesLoaded, setValuesLoaded] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [prod_categories, setProd_categories] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const {user} = useUser();
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true)
    const [wallet, setWallet] = useState(metadata.customer.wallet)
    const [displayOrdered, setDisplayOrdered] = useState(true)
    const [displayReceived, setDisplayReceived] = useState(true)
    const [displaySticker, setDisplaySticker] = useState(true)


    useEffect( ()=>{
        setTotal(metadata.total)
        setRefresh(false);
        setCo([]);
        let totalVar = 0;
        async function fetchData(){
            await firestore.collection(`customer-orders/${metadata.uid}/order/`).get().then((querySnapshot) => {
                querySnapshot.forEach(async doc => {
                    if(doc.id!=='id'){
                        let a = doc.data();
                        let prod = await doc.data().prod_category.get();
                        let supp = await doc.data().supplier.get();
                        prod = prod.data(); supp = supp.data();
                        a.supplier = supp;a.prod_category = prod;
                        // console.log('a :',a);
                        setCo(co => [...co, {...a, checked:false, editting:false}]);
                        // setTotal(total => (total +  a.qty * a.price * (1+a.gst/100) * (1-a.discount/100) ));
                        // console.log('total inside: ',totalVar);
                        // setDisplay(display=> [...display, {...a, checked:false, editting:false}]);
                    }
                });
                setLoading(false); 
            });
        };
        
        if(refresh){
            fetchData();
        }
        // setTotal(totalVar);
        // console.log("setting total: ", totalVar)
        // console.log("metadata: ", metadata)
    },[refresh]);

    const history_toggle = (hist) => {
        setContent({...content, text:hist});
        // const docRef = firestore.doc(`/customer-orders/0/order/0`);
        // await docRef.set({history:content});
        setHistory(true);
    };
    const close = ()=>{
        setHistory(false);
    };

    const checked = (e) => {
        // console.log(e.target.name, e.target.checked);
        let a = [...co];
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        a[idx].checked = e.target.checked;

        //set ordered, sticker, received
        setDisplayOrdered(a.filter( (item) => item.checked && item.status!='default').length==0?true:false)
        setDisplayReceived(a.filter( (item) => item.checked && item.status!='ordered').length==0?true:false)
        setDisplaySticker(a.filter( (item) => item.checked && item.status!='received').length==0?true:false)

        setCo(co=>a);
    }

    const enableEdit=async (uid)=>{
        setCo(co=>co.map((item)=> item.uid==uid?{...item,editting:true}:item));
        if(!valuesLoaded){
            // load suppliers and product categories

            let s = [];
            let pc = [];

            await firestore.collection("suppliers").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id != 'id'){
                        s = [...s, doc.data()];
                    }
                });
            });

            await firestore.collection("product-categories").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id != 'id'){
                        pc = [...pc, doc.data()];
                    }
                });
            });
            setProd_categories(prod_categories => pc);
            setSuppliers(suppliers => s);
            setValuesLoaded(true);

        }
        // display.map((item)=> item.uid==uid?{...item,editting:true}:item);
        // console.log(display);
        // const docRef = firestore.doc('/customer-orders/0/order/3');
        // await docRef.set(co[0]);
    };  

    
    const cancel = (uid)=>{
        setCo(co=>co.map((item)=> item.uid==uid?{...item,editting:false}:item));
    };

    const updateFunc = async (e, uid, newDoc) => {
        // console.log("in update:" , e)
        console.log("updated values: ", e);
        console.log("uid: ", uid)
        console.log("newDoc: ", newDoc)
        const docref =  firestore.doc(`customer-orders/${metadata.uid}/order/${uid}`);
        if(newDoc)
            await docref.set({...e, status:"default"});
        else
            await docref.update(e);
        cancel(uid);
        let newItem = e;

        // update COs in prod categories and supplier
        //search for existing item and delete the CO from them
        let toRemove = {co:metadata.uid, uid: uid}
        if(!newDoc){
            let existing = co.filter((item)=> item.uid == uid)[0]; // im sure to find one
            console.log("existing: ", existing)
            let existingRef = firestore.doc(`/suppliers/${existing.supplier.uid}`)
            
            existingRef.update({
                COs: firebase.firestore.FieldValue.arrayRemove(toRemove)
            })
            existingRef = firestore.doc(`/product-categories/${existing.prod_category.uid}`)
            await existingRef.update({
                COs: firebase.firestore.FieldValue.arrayRemove(toRemove)
            })
        }

        console.log("Exited modifying existing");

        // add co to new suppliers and prod categories
        await newItem.supplier.update({
            COs: firebase.firestore.FieldValue.arrayUnion(toRemove)
        })
        await newItem.prod_category.update({
            COs: firebase.firestore.FieldValue.arrayUnion(toRemove)
        })

        let prod = await newItem.prod_category.get();
        let supp = await newItem.supplier.get();
        prod = prod.data(); supp = supp.data();
        newItem.supplier = supp;newItem.prod_category = prod;
        setCo(co=>co.map((item)=> item.uid==uid?{...item,...e}:item));
        let updatedCO = co.map((item)=> item.uid==uid?{...item,...e}:item);
        await udpdatePayment(updatedCO);
        // props.history.replace(`/customer-orders/${metadata.uid}`,{co:metadata});
        // setRefresh(true);
    };
    
    const udpdatePayment = async (updatedCO) => {
        let updatedCO2 = updatedCO.map((item) => item.status=='cancelled'?0: item.qty * item.price * (1+item.gst/100) * (1-item.discount/100))
        console.log("payment doc: ", metadata.payment)
        const docRef = firestore.doc(`/payments/${metadata.payment}`)
        let originalPayment = (await docRef.get()).data().debit;
        console.log("old total: ", originalPayment)

        console.log("updatedCO2: ",updatedCO2);

        let tempNewPay = 0;
        updatedCO2.forEach(item => {
            tempNewPay += item;
          });

        updatedCO2 = Math.round(tempNewPay*100)/100
        console.log("newTotal: ", updatedCO2)
        const updateMsg = await firestore.doc(`/payments/${metadata.payment}`).update({debit:updatedCO2});
        console.log("update return: ", updateMsg)

        const newWallet = Math.round((metadata.customer.wallet + originalPayment - updatedCO2)*100)/100;
        console.log("old wallet: ", metadata.customer.wallet)
        console.log("New wallet: ", newWallet)
        metadata.customer.wallet = newWallet;
        setWallet(newWallet)
        setTotal(updatedCO2)
        // updating customer wallet
        const custRef = firestore.doc(`/customers/${metadata.customer.uid}`)
        await custRef.update({wallet:newWallet});
        const docRefCo = firestore.doc(`/customer-orders/${metadata.uid}`)
        await docRefCo.update({total:updatedCO2})
    }

    const addItem = async () => {
        console.log(co);
        const idRef = firestore.doc(`/customer-orders/${metadata.uid}/order/id`);
        const id =  (await idRef.get()).data()['id'];
        idRef.update({'id': id+1});
        // creating a defualt object and then sending it in for edit
        let newItem = {
            uid:id,
            discount:0,
            qty:1,
            gst:0,
            price:0,
            prod_sno:0,
            prod_collection:"",
            history:"",
            supplier: {},
            prod_category:{},
            editting:false,
            status: "default"  //can be default, cancelled or stickered
        }
        setCo(co => [...co, newItem]);
        enableEdit(id);
        let updatedCO = [...co, newItem];
        // await udpdatePayment(updatedCO);
    }

    const cancelItems = async () => {
        // console.log("to cancel: ",uid);
        let today = new Date();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let docRef = firestore.doc(`/staff/${user.phoneNumber}`);  //TODO: admin
        let doc = await docRef.get();
        let username = doc.data().name;

        let toCancel = co.filter(item => item.checked == true);
        // console.log("to cancel: ", toCancel);
        // toCancel = toCancel.map(item => item.uid);
        toCancel.forEach( item => {
            let docRef = firestore.doc(`/customer-orders/${metadata.uid}/order/${item.uid}`);
            docRef.update({status:"cancelled", history:`${date} ${time} \nCancelled by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`})
        });
        setCo( co => co.map( item => item.checked==true? {...item,checked:false,status:"cancelled", history:`${date} ${time} \nCancelled by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`}:item));
        let updatedCO = co.map( item => item.checked==true? {...item,checked:false,status:"cancelled", history:`${date} ${time} \nCancelled by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`}:item);
        await udpdatePayment(updatedCO);
    };

    const generateSticker = async () => {
        // console.log("to cancel: ",uid);
        let today = new Date();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let docRef = firestore.doc(`/staff/${user.phoneNumber}`);  //TODO: admin
        let doc = await docRef.get();
        let username = doc.data().name;

        let toCancel = co.filter(item => item.checked == true);
        // console.log("to cancel: ", toCancel);
        // toCancel = toCancel.map(item => item.uid);
        toCancel.forEach( item => {
            let docRef = firestore.doc(`/customer-orders/${metadata.uid}/order/${item.uid}`);
            docRef.update({history:`${date} ${time} \nSticker generated by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`})
        });
        let s = co.filter(item => item.checked);
        setCo( co => co.map( item => item.checked==true? {...item,checked:false, history:`${date} ${time} \nSticker generated by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`}:item));
        let headers = [["Customer Name", "Customer Order No.", "Collection", "Product Sno", "Prod Category", "Qty"]];
        // s = s.map( item => [date, time, item.prod_collection, item.prod_sno, item.supplier.name, item.prod_category.name, item.price, item.qty, item.gst, item.discount, (Math.round(item.qty * item.price*(1+item.gst/100)*(1-item.discount/100)*100)/100)]);
        s = s.map( item => [`${metadata.customer.firstname} ${metadata.customer.lastname}`, metadata.billno, item.prod_collection, item.prod_sno, item.prod_category.name, item.qty]);
        exportPDF(headers, s);
    };

    const ordered = async () => {
        // console.log("to cancel: ",uid);
        let today = new Date();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let docRef = firestore.doc(`/staff/${user.phoneNumber}`);  //TODO: admin
        let doc = await docRef.get();
        let username = doc.data().name;

        let toCancel = co.filter(item => item.checked == true);
        // TODO: add validation
        toCancel.forEach( item => {
            let docRef = firestore.doc(`/customer-orders/${metadata.uid}/order/${item.uid}`);
            docRef.update({status:"ordered", history:`${date} ${time} \nOrder Placed by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`})
        });
        let s = co.filter(item => item.checked);
        setCo( co => co.map( item => item.checked==true? {...item,checked:false,status:"ordered", history:`${date} ${time} \nOrder Placed by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`}:item));
        let headers = [["Date", "Time", "Collection", "Product Sno", "Supplier", "Prod Category", "Price", "Qty", "GST", "Discount", "Amount"]];
        s = s.map( item => [date, time, item.prod_collection, item.prod_sno, item.supplier.name, item.prod_category.name, item.price, item.qty, item.gst, item.discount, (Math.round(item.qty * item.price*(1+item.gst/100)*(1-item.discount/100)*100)/100)]);
    };

    const received = async () => {
        // console.log("to cancel: ",uid);
        let today = new Date();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let docRef = firestore.doc(`/staff/${user.phoneNumber}`);  //TODO: admin
        let doc = await docRef.get();
        let username = doc.data().name;

        let toCancel = co.filter(item => item.checked == true);
        // TODO: add validation
        toCancel.forEach( item => {
            let docRef = firestore.doc(`/customer-orders/${metadata.uid}/order/${item.uid}`);
            docRef.update({status:"received", 
                            history:`${date} ${time} \nItem Received by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`,
                            receiving_date: firebase.firestore.Timestamp.fromDate(new Date())
                        })
        });
        let s = co.filter(item => item.checked);
        setCo( co => co.map( item => item.checked==true? {...item,checked:false,status:"received", history:`${date} ${time} \nItem Received by ${username} (${user.phoneNumber}) \n\n------------------------\n${item.history}`}:item));
        let headers = [["Date", "Time", "Collection", "Product Sno", "Supplier", "Prod Category", "Price", "Qty", "GST", "Discount", "Amount"]];
        s = s.map( item => [date, time, item.prod_collection, item.prod_sno, item.supplier.name, item.prod_category.name, item.price, item.qty, item.gst, item.discount, (Math.round(item.qty * item.price*(1+item.gst/100)*(1-item.discount/100)*100)/100)]);
    };

    const cancelOrder = async () => {
        let docRef = firestore.doc(`customer-orders/${metadata.uid}`)
        await docRef.update({status:"cancelled"});
        //update customer wallet
        const custRef = firestore.doc(`/customers/${metadata.customer.uid}`)
        custRef.update({wallet: wallet + total})

        //update payment
        const payRef = firestore.doc(`/payments/${metadata.payment}`)
        payRef.update({debit: 0})

        props.history.push('/customer-orders');
    }

    const revive = async () => {
        let docRef = firestore.doc(`customer-orders/${metadata.uid}`)
        await docRef.update({status:"pending"});
        //update customer wallet
        const custRef = firestore.doc(`/customers/${metadata.customer.uid}`)
        custRef.update({wallet: wallet - total})

        //update payment
        const payRef = firestore.doc(`/payments/${metadata.payment}`)
        payRef.update({debit: total})
        props.history.push(`/customer-orders/`);
    }
    const edit = () => {
        props.history.push(`/customer-orders/edit/${metadata.uid}`, {co:metadata});
    };


    return (
        <>
        {/* {console.log(co)} */}
        <Body2>
            {/* {console.log('totalVar:', total)} */}
            <Title>Customer Order {metadata.uid} {metadata.status!="cancelled"?<Buttons color="red" onClick={cancelOrder}>Cancel Order</Buttons>:<Buttons onClick={revive}>Revive</Buttons>} {metadata.status!='cancelled'?<Buttons onClick={edit}>Edit</Buttons>:<></>} </Title> 
            
            <Box2>
                <CoDetails wallet={wallet} metadata={metadata} total={total} amounts={co.map((item) => item.status=='cancelled'?0: item.qty * item.price * (1+item.gst/100) * (1-item.discount/100))}/>
                <CustDetails metadata={metadata} />
            </Box2>

            {/* <CustInfo cust={metadata.customer}></CustInfo> */}
            <Box>
                <header>
                    {metadata.status != 'cancelled' ? <>
                    <Buttons color="red" onClick={async () => await cancelItems()}>Cancel Items</Buttons>
                    {displaySticker?<Buttons onClick={generateSticker}>Generate Sticker</Buttons>:<></>}
                    {displayReceived?<Buttons color="green" onClick={received}>Items Received</Buttons>:<></>}
                    {displayOrdered?<Buttons color="amber" onClick={ordered}>Orders Placed</Buttons>:<></>}
                    <Buttons color="grey" onClick={async ()=> await addItem()}>Add Item</Buttons> </> : <></>
                }
                </header>

                <Table>
                    <colgroup>
                    <col span="1" style={{width: '4%'}}/>
                    <col span="1" style={{width: '9%'}}/>
                    <col span="1" style={{width: '13%'}}/>
                    <col span="1" style={{width: '11%'}}/>
                    <col span="1" style={{width: '10%'}}/>
                    <col span="1" style={{width: '9%'}}/>
                    <col span="1" style={{width: '7%'}}/>
                    <col span="1" style={{width: '7%'}}/>
                    <col span="1" style={{width: '9%'}}/>
                    <col span="1" style={{width: '10%'}}/>
                    <col span="1" style={{width: '5%'}}/>
                    <col span="1" style={{width: '3%'}}/>
                    <col span="1" style={{width: '3%'}}/>
                </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>Prod Collection</th>
                            <th>Prod SNo/Color</th>
                            <th>Supplier</th>
                            <th>Prod Category</th>
                            
                            <th>Qty</th>
                            <th>Price</th>
                            <th>GST(%)</th>
                            {/* <th>Total Pending</th> */}
                            <th>Discount(%)</th>
                            <th>Amount</th>
                            <th>History</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </tbody></Table>

                        {/* {console.log(display)} */}
                        { loading? <Loading><div className='dot-spin'/></Loading> :co.map((item,id) =>  item.editting?
                            <ItemForm defaults={item} suppliers ={suppliers} pc={prod_categories} cancel={()=>cancel(item.uid)} updateFunc={async (e, uid, newDoc) => await updateFunc(e, uid, newDoc)}/>
                            :
                            <Table key={item.uid}>
                                <colgroup>
                                    <col span="1" style={{width: '4%'}}/>
                                    <col span="1" style={{width: '9%'}}/>
                                    <col span="1" style={{width: '13%'}}/>
                                    <col span="1" style={{width: '11%'}}/>
                                    <col span="1" style={{width: '10%'}}/>
                                    <col span="1" style={{width: '9%'}}/>
                                    <col span="1" style={{width: '7%'}}/>
                                    <col span="1" style={{width: '7%'}}/>
                                    <col span="1" style={{width: '9%'}}/>
                                    <col span="1" style={{width: '10%'}}/>
                                    <col span="1" style={{width: '5%'}}/>
                                    <col span="1" style={{width: '3%'}}/>
                                    <col span="1" style={{width: '3%'}}/>
                                </colgroup><tbody>
                                <TR status={item.status} ><td>{item.status!="cancelled"?<input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /> : <></>}</td>
                                    <td>{item.prod_collection}</td>
                                    <td>{item.prod_sno}</td>
                                    <td>{item.supplier.name}</td>
                                    <td>{item.prod_category.name}</td>
                                    
                                    <td>{item.qty}</td>
                                    <td>{item.price}</td>
                                    <td>{item.gst}</td>
                                    <td>{item.discount}</td>
                                    {/* <td>{item.pending}</td> */}
                                    <td>{Math.round(item.qty * item.price*(1+item.gst/100)*(1-item.discount/100)*100)/100}</td>
                                    <td onClick={()=>history_toggle(item.history)}><Buttons>View</Buttons></td>
                                    <td>{item.status!="cancelled" && metadata.status!='cancelled'?<EditIcon onClick={()=>enableEdit(item.uid)} src={editIcon}/>:''}</td>
                                    <td></td>
                                    <td></td>
                                    </TR>
                            </tbody></Table>
                        )}

                    
            </Box>
        </Body2>
        {history? <ExistPopup content = {content.text} header={content.header} edit={false} close={close}/> :""}
        </>
    );

};

const TR = styled.tr`
    background-color:${ ({theme, status})=> status=='cancelled'?theme.colors.darkGrey:
                            status=='received'?theme.colors.green:
                                status =='ordered'?theme.colors.amber:
                                    theme.colors.white}50;
    // background-opacity:0.5;
`;

const EditIcon = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.3rem;
`;

export default CO;

export const Loading = styled.div`
    position: absolute;
    top: 50%;
    right:50%;    
    z-index:3;
`
;