import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const MergeSupplier = (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [user, setUser] = useState({uid:[], name:[]});
    const [loading, setLoading] = useState(false)

    useEffect( ()=> {
        const refs = props.history.location.state.refs;
        // console.log("refs: ",refs);
        let obj = {uid:[], name:[],};
        refs.forEach( (data) => { obj = {uid:[...obj.uid, data.uid], name:[...obj.name,data.name]}});
        setUser(obj);
        //set values
        setValue('uid', obj.uid[0]);
        setValue('name', obj.name[0]);
        // console.log("obj: ",obj);
    }, []);

    const cancel = () => {
        props.history.push('/suppliers');
    }

    const merge = async (supplier) => {
        setLoading(true)
        console.log("merging: ",supplier);
        let COs = [];
        let cosToUpdate = [];  
        let refs = props.history.location.state.refs;
        // delete all objects
        // create a new object with the updated details
        // update all records (customer orders, payments etc)
        refs.forEach( async (ref) => {
            const a = firestore.doc(`/suppliers/${ref.uid}`);
            COs = [...COs, ...ref.COs];
            if(ref.uid!=supplier.uid)
                cosToUpdate = [...cosToUpdate, ...ref.COs];
            await a.delete();
        });

        let docRef = firestore.doc(`/suppliers/${supplier.uid}`);

        //updating all the customer orders
        cosToUpdate.forEach( async (co) => {
            let ref = firestore.doc(`/customer-orders/${co.co}/order/${co.uid}`)
            ref.update({supplier: docRef})
        });

        docRef = firestore.doc(`/suppliers/${supplier.uid}`);
        docRef.set({...supplier, COs:COs});
        props.history.replace('/suppliers');
        setLoading(false)
    };
    
    return(
        <Body>
            <Title>Merge Suppliers</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(merge)}>
                <Table><tbody>
                    {/* {console.log("to be merged: ", user)} */}
                    <tr>
                        <td>Supplier ID</td><td><Select  
                                                    key={1}  
                                                    {...register('uid')}>
                                                    {user.uid.map( (uid,id) => <Option key={id} value={uid}>{uid}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>Name</td><td><Select 
                                                    key={2}  
                                                    {...register('name')}>
                                                    {user.name.map( (name,id) => <Option key={id} value={name}>{name}</Option>)}
                                                </Select> 
                                                    </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {/* {props.editing? <Button onClick={handleSubmit(merge)} color={'green'}>Merge</Button> : */}
                            <Button type = 'submit' value='Submit' color={'green'}>Merge</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
    );
}

export default MergeSupplier;

    
