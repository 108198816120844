import styled from 'styled-components'

const Title = styled.h1`
    color : ${({color,theme}) => color ? theme.colors.seaBlue : theme.colors.darkGrey};
    font-size: 3 rem;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 899;
    font-family = ${ ({theme}) => theme.fonts.titles};
    display: block;
    margin-top:0
`;

export default Title