//TODO: for merging, change the records in customer orders and payments etc

import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const MergeCustomer = (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({uid:[],
        firstname:[],
        lastname:[],
        company:[],
        address:[],
        credit_auth:[],
        credit_limit:[]});

    useEffect( ()=> {
        const refs = props.history.location.state.refs;
        // console.log("refs: ",refs);
        let obj = {uid:[],
            firstname:[],
            lastname:[],
            company:[],
            address:[],
            credit_auth:[],
            credit_limit:[]};
        refs.forEach( (data) => { obj = {uid:[...obj.uid, data.uid], 
                                                firstname:[...obj.firstname,data.firstname],
                                                lastname:[...obj.lastname, data.lastname],
                                                company:[...obj.company, data.company],
                                                address: [...obj.address, data.address],
                                                credit_auth:[...obj.credit_auth, data.credit_auth],
                                                credit_limit:[...obj.credit_limit, data.credit_limit]}});
        setUser(obj);
        //set values
        setValue('uid', obj.uid[0]);
        setValue('firstname', obj.firstname[0]);
        setValue('lastname', obj.lastname[0]);
        setValue('company', obj.company[0]);
        setValue('credit_auth', obj.credit_auth[0]);
        setValue('credit_limit', obj.credit_limit[0]);
        setValue('address', obj.address[0]);

        // console.log("obj: ",obj);
        
    }, []);

    const cancel = () => {
        props.history.push('/customers');
    }

    const merge = async (customer) => {
        setLoading(true)
        if(customer.credit_auth!=='L2'){
            customer.credit_limit=0;
        }
        console.log("merging: ",customer);
        let refs = props.history.location.state.refs;
        let wallet_total = 0;
        let payments = []
        let COs = [];
        let cosToUpdate = [];  // all co ids except the customer finally chosen
        let payments_to_update = [];
        // delete all objects
        // create a new object with the updated details
        // update all records (customer orders, payments etc)
        refs.forEach( async (ref) => {
            const a = firestore.doc(`/customers/${ref.uid}`);
            wallet_total = wallet_total + ref.wallet;
            payments = [...payments, ...ref.payments];
            COs = [...COs, ...ref.COs];
            if(ref.uid!=customer.uid){
                cosToUpdate = [...cosToUpdate, ...ref.COs];
                payments_to_update = [...payments_to_update, ...ref.payments];
            }
            await a.delete();
        });
        const docRef = firestore.doc(`/customers/${customer.uid}`);

        //updating all the customer orders
        cosToUpdate.forEach( async (co) => {
            let CoRef = firestore.doc(`/customer-orders/${co}`);
            CoRef.update({customer: docRef})
        });

        payments_to_update.forEach( async (co) => {
            let CoRef = firestore.doc(`/payments/${co}`);
            CoRef.update({customer: parseInt(customer.uid)})
        });

        await docRef.set({...customer, wallet: wallet_total, payments:payments, COs:COs});
        props.history.replace('/customers');
        setLoading(false)
    };
    
    return(
        <Body>
            <Title>Merge Customers</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
                <Form onSubmit = {handleSubmit(merge)}>
                <Table><tbody>
                    {/* {console.log("to be merged: ", user)} */}
                    <tr>
                        <td>Customer ID</td><td><Select  
                                                    key={1}  
                                                    {...register('uid')}>
                                                    {user.uid.map( (uid,id) => <Option key={id} value={uid}>{uid}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>First Name</td><td><Select 
                                                    key={2}  
                                                    {...register('firstname')}>
                                                    {user.firstname.map( (firstname,id) => <Option key={id} value={firstname}>{firstname}</Option>)}
                                                </Select> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Last Name</td><td><Select 
                                                    key={3}  
                                                    {...register('lastname')}>
                                                    {user.lastname.map( (lastname,id) => <Option key={id} value={lastname}>{lastname}</Option>)}
                                                </Select> 
                                                </td>
                    </tr>
                    <tr>
                        <td>Company</td><td><Select  
                                                    key={4}  
                                                    {...register('company')}>
                                                    {user.company.map( (company,id) => <Option key={id} value={company}>{company}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>Address</td><td><Select 
                                                    key={5}  
                                                    {...register('address')}>
                                                    {user.address.map( (address,id) => <Option key={id} value={address}>{address}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr> 
                        <td>Credit Authorisation</td><td><Select  
                                                    key={6}  
                                                    {...register('credit_auth')}>
                                                    {user.credit_auth.map( (credit_auth,id) => <Option key={id} value={credit_auth}>{credit_auth}</Option>)}
                                                </Select>  </td>
                    </tr>
                    <tr>
                        <td>Credit Limit</td><td><Select 
                                                    key={7}  
                                                    {...register('credit_limit')} disabled={watch('credit_auth') ==='L2'?false:true} >
                                                    {user.credit_limit.map( (credit_limit,id) => <Option key={id} value={credit_limit}>{credit_limit}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {/* {props.editing? <Button onClick={handleSubmit(merge)} color={'green'}>Merge</Button> : */}
                            <Button type = 'submit' value='Submit' color={'green'}>Merge</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
    );
}

export default MergeCustomer;

    
