import React, { useContext, useEffect, useState, useRef } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import SelectSearch from 'react-select-search';
// import "../styles/style.css";
import "../styles/broader-select-search.css";
import firebase from 'firebase/app'
import { Loading } from './CustomerOrder'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const NewCO =  (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [exists, setExists] = useState(false);
    const [content, setContent] = useState({header: "Architect Already Exists", text: ""});
    const [user, setUser] = useState();
    const [currentId, setCurrentId] = useState(0);

    const [isrs, setIsrs] = useState([]);
    const [osrs, setOsrs] = useState([]);
    const [architects, setArchitects] = useState([]);
    const [customers, setCustomers] = useState([]);

    const [isrOptions, setIsrOptions] = useState([]);
    const [osrOptions, setOsrOptions] = useState([]);
    const [archOptions, setArchOptions] = useState([]);
    const [custOptions, setCustOptions] = useState([]);
    const [updated, setUpdated] = useState( {});
    const [cust, setCust] = useState("");

    const [date,setDate] = useState();
    const [dateFull,setDateFull] = useState();
    const [fields, setFields] = useState();
    const [customeErrors, setErrors] = useState();
    const [loading, setLoading] = useState(true)

    // const searchInput = useRef();

    useEffect( () => {
        // console.log(props);
        if(props.editing)
            console.log("metadata to edit: ", props.metadata);
        const fetchId = async () => {
            const idRef = firestore.doc(`/customer-orders/id`);
            const id = (await idRef.get()).data()['id'];
            setCurrentId(id+1);
        };

        const fetchValues = async () => {
            let s = [];
            let pc = [];
            let arch = [];
            let custs = [];

            await firestore.collection("ISRs").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id != 'id'){
                        s = [...s, doc.data()];
                    }
                });
            });

            await firestore.collection("OSRs").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id != 'id'){
                        pc = [...pc, doc.data()];
                    }
                });
            });

            await firestore.collection("architects").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    arch = [...arch, doc.data()];
                });
            });

            await firestore.collection("customers").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    custs = [...custs, doc.data()];
                });
            });

            setOsrs(osrs => pc);
            setIsrs(isrs => s);
            setArchitects(architects => arch);
            setCustomers(customers => custs);
            setIsrOptions(s.map((item)=> ({name:item.name, value:item.uid, item:item})));
            setOsrOptions(pc.map((item)=> ({name:item.name, value:item.uid, item:item})));
            setArchOptions(arch.map((item) => ({name:`${item.firstname} ${item.lastname}`, value:item.uid, item:item})));
            setCustOptions(custs.map((item) => ({name:`${item.firstname} ${item.lastname}`, value:item.uid, item:item})));
        }

        fetchId();
        fetchValues();
        setLoading(false)
        let today = new Date();
        let dateTemp = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() ;
        setDate(dateTemp);
        // setDateFull(today)
    }, [])
   

    const close = () =>{
        setExists(false);
    }

    const handleFilterIsr = (items) => {
        return (searchValue) => {
          if (searchValue.length === 0) {
            return isrOptions;
          }
          const updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
            });
          return updatedItems;
        };
      };

    const handleFilterCustomers = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
            return custOptions;
            }
            const updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
            });
            return updatedItems;
        };
    };

    const handleFilterArch = (items) => {
    return (searchValue) => {
        if (searchValue.length === 0) {
        return archOptions;
        }
        const updatedItems = items.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
        });
        return updatedItems;
    };
    };

    const handleFilterOsr = (items) => {
    return (searchValue) => {
        if (searchValue.length === 0) {
        return osrOptions;
        }
        const updatedItems = items.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
        });
        return updatedItems;
    };
    };


    const handleChangeIsr = (...args) => {
    setUpdated( {...updated, isr:args[1].value});
    };

    const handleChangeArch = (...args) => {
        setUpdated( {...updated, architect:args[1].value});
        };    

    const handleChangeOsr = (...args) => {
    setUpdated( {...updated, osr:args[1].value});
    };

    const handleChangeCust = (...args) => {
        setUpdated( {...updated, customer:args[1].value});
        setCust(args[1].value);
        };
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
        

    const update = async (e) => {
        setLoading(true)
        console.log("in update");
        let input = {
            customer: updated.customer?firestore.doc(`/customers/${updated.customer}`):firestore.doc(`/customers/${props.metadata.customer.uid}`),
            billno: e.target[5].value.toUpperCase(),
            comp: e.target[2].value,
        }
        let a = {...input, architect:{}, isr:{}, osr:{}};

        if(updated.customer){
            //updating CO arrays
            var docRef = firestore.doc(`/customers/${props.metadata.customer.uid}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayRemove(props.metadata.uid),
                payments: firebase.firestore.FieldValue.arrayRemove(props.metadata.payment),
            });
            docRef = firestore.doc(`/customers/${updated.customer}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayUnion(props.metadata.uid),
                payments: firebase.firestore.FieldValue.arrayUnion(props.metadata.payment)
            });
            docRef = firestore.doc(`/payments/${props.metadata.payment}`)
            docRef.update({customer:parseInt(updated.customer)})
        }

        if(updated.architect){
            input = {...input, architect:firestore.doc(`/architects/${updated.architect}`)}
            let temp = await input.architect.get();
            a.architect = temp.data();

            //updating CO arrays
            var docRef = firestore.doc(`/architects/${props.metadata.architect.uid}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayRemove(props.metadata.uid)
            });
            docRef = firestore.doc(`/architects/${updated.architect}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayUnion(props.metadata.uid)
            });
        }
        if(updated.isr){
            input = {...input, isr:firestore.doc(`/ISRs/${updated.isr}`)}
            let temp = await input.isr.get();
            a.isr = temp.data();

            //updating CO arrays
            var docRef = firestore.doc(`/ISRs/${props.metadata.isr.uid}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayRemove(props.metadata.uid)
            });
            docRef = firestore.doc(`/ISRs/${updated.isr}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayUnion(props.metadata.uid)
            });
        }
        if(updated.osr){
            input = {...input, osr:firestore.doc(`/OSRs/${updated.osr}`)}
            let temp = await input.osr.get();
            a.osr = temp.data();

            //updating CO arrays
            var docRef = firestore.doc(`/OSRs/${props.metadata.osr.uid}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayRemove(props.metadata.uid)
            });
            docRef = firestore.doc(`/OSRs/${updated.osr}`)
            docRef.update({ 
                COs: firebase.firestore.FieldValue.arrayUnion(props.metadata.uid)
            });
        }
        docRef =  await firestore.doc(`/customer-orders/${props.metadata.uid}`);
        await docRef.update(input);
        let cust = await input.customer.get();
        cust = cust.data();
        a.customer = cust;
        a = {...a, total:props.metadata.total, paid: props.metadata.paid, uid:props.metadata.uid, status:props.metadata.status, date:props.metadata.date}
        props.history.replace(`/customer-orders/view/${props.metadata.uid}`, {co:a});
        setLoading(false)
    };

    const createCO = async (e) =>{
        setLoading(true)
        e.preventDefault();
        // update CO Id
        if(props.editing)
            update(e);
        else{
            if(!updated.customer){
                console.log("No customer")
                alert("Please provide a customer ID");
            }
            else{
                //creating new payment
                const payIdRef = firestore.doc(`/payments/id`);
                let pay_id = (await payIdRef.get()).data()['id'];
                pay_id = pay_id+1;
                payIdRef.update({id:pay_id})
                const pay_ref = firestore.doc(`/payments/${pay_id}`);

                const idRef = firestore.doc(`/customer-orders/id`);
                const id =  (await idRef.get()).data()['id'];
                idRef.update({'id': id+1});

                const paymentObj = {
                    uid: pay_id,
                    date: date,
                    customer: parseInt(updated.customer),
                    credit: 0,
                    debit: 0,
                    mode: "",
                    description:`Bill for CO:${currentId}`,
                    status:'default'
                };
                pay_ref.set(paymentObj);
                // console.log("e.target value 0 :",e.target[0].value)
                // console.log("e.target value 1 :",e.target[1].value)
                // console.log("e.target value 2 :",e.target[2].value)
                // console.log("e.target value 3 :",e.target[3].value)
                // console.log("e.target value 4 :",e.target[4].value)
                // console.log("e.target value 5 :",e.target[5].value)
                // console.log("e.target value 6 :",e.target[6].value)
                // console.log("e.target value 7 :",e.target[7].value)
                // console.log("e.target value 8 :",e.target[8].value)


                let input = {
                    date: date,
                    uid: currentId,
                    customer: firestore.doc(`/customers/${updated.customer}`),
                    architect: updated.architect?firestore.doc(`/architects/${updated.architect}`):firestore.doc(`/architects/-1`),
                    isr: updated.isr?firestore.doc(`/ISRs/${updated.isr}`):firestore.doc(`/ISRs/-1`),
                    osr: updated.osr?firestore.doc(`/OSRs/${updated.osr}`):firestore.doc(`/OSRs/-1`),
                    billno: e.target[6].value.toUpperCase(),
                    comp: e.target[3].value,
                    total:0,
                    paid:0,
                    status:"pending",
                    dateStamp : firebase.firestore.Timestamp.fromDate(new Date()),
                    payment: pay_id
                }


                let docRef =  await firestore.doc(`/customer-orders/${currentId}`);
                await docRef.set(input);

                // setting up the ID document
                docRef = await firestore.doc(`/customer-orders/${currentId}/order/id`);
                await docRef.set({id:0});

                //updating COs arrays in customers, ISRs, OSRs and architects
                var custRef = firestore.doc(`/customers/${updated.customer}`);
                custRef.update({ 
                    COs: firebase.firestore.FieldValue.arrayUnion(currentId),
                    payments: firebase.firestore.FieldValue.arrayUnion(pay_id)
                });
                var ISRRef = firestore.doc(`/ISRs/${updated.isr}`);
                ISRRef.update({ 
                    COs: firebase.firestore.FieldValue.arrayUnion(currentId)
                });
                var OSRRef = firestore.doc(`/OSRs/${updated.osr}`);
                OSRRef.update({ 
                    COs: firebase.firestore.FieldValue.arrayUnion(currentId)
                });
                var archRef = firestore.doc(`/architects/${updated.architect}`);
                archRef.update({ 
                    COs: firebase.firestore.FieldValue.arrayUnion(currentId)
                });


                // fetching CO details to pass to the CO page
                let a = input;
                let cust = await input.customer.get();
                let arch = await input.architect.get();
                let isr = await input.isr.get();
                let osr = await input.osr.get();
                a.architect = arch.exists?arch.data():{};
                a.isr = isr.exists?isr.data():{};
                a.osr = osr.exists?osr.data():{};
                cust = cust.data();
                a.customer = cust;
                props.history.push(`/customer-orders/view/${currentId}`, {co:a});
            }
        }
        setLoading(false)
    }

    const cancel = () => {
        props.history.push('/customer-orders');
    }

    return(
        <>
        <Body>
            <Title>{props.editing?`Editing Order: ${props.metadata.uid}`:"New Customer Order"}</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {(createCO)}>
                <Table className="newSelect"><tbody>
                    
                    <tr>
                        <td>CO ID</td><td>{props.editing?props.metadata.uid:currentId}</td>
                    </tr>
                    <tr>
                        <td >Date</td><td style={{zIndex:1000, position:'relative'}}>{props.editing?props.metadata.date:
                            <DatePicker  className='datePicker'
                            value = {date}
                            onChange={(update) => {
                                setDateFull(update)
                                // console.log(update);
                                // console.log(new Date(dateFull))
                                let dateTemp = update.getDate() + '/' + (update.getMonth() + 1) + '/' + update.getFullYear() ;
                                setDate(dateTemp)
                                console.log(dateTemp)
                                // setDate(update);
                            }}
                            placeholderText="Select a date"
                            

                        />}</td>
                    </tr>
                    <tr>
                        <td>Customer Name</td><td style={{zIndex:-1}}><SelectSearch 
                                                key={0} 
                                                // ref={searchInput}
                                                error={errors.customer && errors.customer.type === "required"} 
                                                options={custOptions} 
                                                filterOptions={handleFilterCustomers}
                                                {...register('customer', { required: true })} 
                                                search
                                                onChange={handleChangeCust}
                                                placeholder="Search by ID or Name" 
                                                // value = {props.editing?props.metadata.customer.uid:""}
                                                />
                                                </td>
                    </tr>
                    <tr>
                        <td>Customer ID</td><td>{props.metadata?.customer?'Old: ':''} {props.metadata?.customer?.uid} {cust}</td>
                    </tr>
                    <tr>
                        <td>Architect</td><td style={{zIndex:-1}}><SelectSearch
                                                key={2} 
                                                // ref={searchInput}
                                                error={errors.architect && errors.architect.type === "required"} 
                                                options={archOptions} 
                                                filterOptions={handleFilterArch}
                                                {...register('architect', { required: true })} 
                                                search
                                                onChange={handleChangeArch}
                                                placeholder="Architect" /> </td>
                    </tr>
                    <tr> 
                    <td>Comp</td><td style={{zIndex:-1}}><Select error={errors.comp && errors.comp.type === "required"} key={3}  {...register('comp', { required: true })} defaultValue="No">
                                        <Option value="No">No</Option>
                                        <Option value="Yes">Yes</Option>
                                    </Select> </td>
                    </tr>
                    <tr>
                        <td>ISR</td><td style={{zIndex:-1}}><SelectSearch
                                            key={7} 
                                            // ref={searchInput}
                                            error={errors.isr && errors.isr.type === "required"} 
                                            options={isrOptions} 
                                            filterOptions={handleFilterIsr}
                                            {...register('isr', { required: true })} 
                                            search
                                            onChange={handleChangeIsr}
                                            placeholder="Search by ID or Name" /></td>
                    </tr>
                    <tr>
                        <td>OSR</td><td style={{zIndex:-1}}><SelectSearch
                                            key={8} 
                                            // ref={searchInput}
                                            error={errors.osr && errors.osr.type === "required"} 
                                            options={osrOptions} 
                                            filterOptions={handleFilterOsr}
                                            {...register('osr', { required: true })} 
                                            search
                                            onChange={handleChangeOsr}
                                            placeholder="Search by ID or Name" /> </td>
                    </tr>
                    <tr>
                        <td>Customer Order No.</td><td style={{zIndex:-1}}><Input 
                                                    error={errors.bno && errors.bno.type === "required"} 
                                                    key={9} 
                                                    type='text' {...register('bno', { required: true })} 
                                                    placeholder = "Bill Number"
                                                    defaultValue={props.editing?props.metadata.billno:""}/> </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {/* {props.editing? <Button onClick={handleSubmit(update)} color={'green'}>Update</Button> : */}
                            <Button type = 'submit' value='Submit' color={'green'}>{!props.editing?"Create CO":"Update"}</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
        {/* {exists? <ExistPopup content = {content.text} header={content.header} edit={edit} close={close}/> :""} */}
        </>
    );
}

export default NewCO;

