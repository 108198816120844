import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
    body {
        background: #ECF0F1;
        // padding: 15rem;
        z-index:0;
    }
    Link{
        textDecoration: none;
    }
    button{
        cursor: pointer;
    }
`;

 
export default GlobalStyle;