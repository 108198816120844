import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const NewISR =  (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [exists, setExists] = useState(false);
    const [content, setContent] = useState({header: "ISR Already Exists", text: ""});
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false)

    const close = () =>{
        setExists(false);
    }

    const edit = ()=> {
        props.history.push(`/ISRs/edit-isr/${user.uid}`, {user : user});
    }

    const createUser = async (user) =>{
        setLoading(true)
        setUser(user);
        const doc =  await firestore.doc(`/ISRs/${user.uid}`).get();
        if(doc.exists){
            // console.log(doc.data())
            setContent({...content, text: `ID: ${doc.data()['uid']}\n
                                            Name: ${doc.data()['name']}\n`});
            setExists(true);
        } 
        else {
            const docRef = firestore.doc(`/ISRs/${user.uid}`);
            // create user object
            const userProfile = {
                uid: user.uid,
                name: user.name,
                COs:[]
            };

            // write to Cloud Firestore
            await docRef.set(userProfile);
            props.history.push('/ISRs');
             
        }
        setLoading(false);
    }

    const cancel = () => {
        props.history.push('/ISRs');
    }

    const update = async (newIsr) =>{
        console.log(newIsr);
        const docRef = firestore.doc(`/ISRs/${props.user.uid}`);
        await docRef.update({...newIsr, uid:props.user.uid});
        props.history.push('/ISRs');
    }


    return(
        <>
        <Body>
            <Title>New ISR</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(createUser)}>
                <Table><tbody>
                    
                    <tr>
                        <td>ID</td><td><Input 
                                                    error={errors.uid && errors.uid.type === "required"} 
                                                    key={1} 
                                                    type='tel' {...register('uid', { required: !props.editing })} 
                                                    placeholder = "Enter UID"
                                                    disabled={props.editing}
                                                    defaultValue={props.editing?props.user.uid:""}/> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Name</td><td><Input 
                                                    error={errors.name && errors.name.type === "required"} 
                                                    key={2} 
                                                    type='text' {...register('name', { required: true })} 
                                                    placeholder = "Enter Name"
                                                    defaultValue={props.editing?props.user.name:""}/> 
                                                    </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {props.editing? <Button onClick={handleSubmit(update)} color={'green'}>Update</Button> :
                            <Button type = 'submit' value='Submit' color={'green'}>Create ISR</Button>}
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
        {exists? <ExistPopup content = {content.text} header={content.header} edit={edit} close={close}/> :""}
        </>
    );
}

export default NewISR;

