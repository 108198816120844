// search inspired from : https://levelup.gitconnected.com/building-a-simple-dynamic-search-bar-in-react-js-f1659d64dfae

import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import ComingSoon from '../components/ComingSoon'
import styled from 'styled-components'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'


const CustomerOrders = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [customerOrders, setCustomerOrders] = useState([]);
    const [display, setDisplay] = useState([]);
    const [popup, setPopup] = useState({comingSoon:false});
    const [temp, setTemp] = useState();
    const [id, setId] = useState(1);
    const [loading, setLoading] = useState(true)
    let displayTemp = [];


    useEffect( ()=>{
        
        async function fetchData (){
            await firestore.collection("customer-orders").orderBy("uid", "desc").get().then(async (querySnapshot) => {
                console.log(querySnapshot.size)
                let count = 0
                querySnapshot.forEach(async doc => {
                    count += 1;
                    if(doc.id!=='id'){
                        // console.log(doc.id)
                        let a = doc.data();
                        displayTemp = [...displayTemp, {...a}];
                        console.log(displayTemp, doc.id)
                        if(count == querySnapshot.size){
                            console.log("count reached with display temp: ", displayTemp)
                            
                            await updateDisplay(displayTemp)
                        }
                        // setDisplay(display=> [...display, {...a, checked:false}]);
                    }
                });
                // console.log("displayTemp: ", display)
                // setLoading(false)
            })
            
        }
        fetchData();

        console.log(customerOrders);
    },[]);

    

    const updateDisplay = async (dTemp) => {
        dTemp =  dTemp.map( async (a) => {
            let cust = await a.customer.get();
            let arch = await a.architect.get();
            let isr = await a.isr.get();
            let osr = await a.osr.get();
            a.architect = arch.exists?arch.data():{};
            a.isr = isr.exists?isr.data():{};
            a.osr = osr.exists?osr.data():{};
            cust = cust.data();
            a.customer = cust;
            a.comp = a.comp?'Yes':'No';
            return {...a, checked:false}
        })
        dTemp = await Promise.all(dTemp)
        console.log("dtemp: ", dTemp)
        setDisplay(dTemp)
        setCustomerOrders(dTemp)
        setLoading(false)
    }


    const newCustomer =()=>{
        props.history.push('/customer-orders/new-customer-order')
    };

    const merge = async (data) => {
        // const docRef = firestore.doc(`/customer-orders/${id}`)
        // await docRef.set(temp);
        // setId(id+1);
        // console.log(data);
        console.log("merge data: ",data);
        let refs = [];
        for(const prop in data){
            if(data[prop]===true){
                console.log(data[prop]);
                const doc = await firestore.doc(`customer-orders/${prop}`).get();
                const temp = doc.data();
                // console.log(temp);
                refs.push(temp);
            }
        }
        // setPopup({...popup, comingSoon:true})
        props.history.push('/customer-orders/merge',{refs:refs});
        // setPopup({...popup, comingSoon:true})
    }
    const close = ()=>{setPopup({...popup, comingSoon:false})}

    const checked = (e) => {
        // console.log(e.target.name, e.target.checked);
        let a = [...customerOrders];
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        a[idx].checked = e.target.checked;
        setCustomerOrders(customerOrders=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        console.log(display);
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    

    const updateSearchResults = (e)=>{ 
        setDisplay(customerOrders.filter(co => 
            co.uid.toString().includes(e.target.value.toLowerCase()) ||
            co.date.toLowerCase().includes(e.target.value.toLowerCase()) ||
            co.status.toLowerCase().includes(e.target.value.toLowerCase())||
            co.customer.firstname.toLowerCase().includes(e.target.value.toLowerCase())||
            co.customer.lastname.toLowerCase().includes(e.target.value.toLowerCase())||
            co.customer.uid.toString().toLowerCase().includes(e.target.value.toLowerCase())||
            (isEmpty(co.architect)?false:co.architect.firstname.toLowerCase().includes(e.target.value.toLowerCase()))||
            (isEmpty(co.architect) ?false: co.architect.lastname.toLowerCase().includes(e.target.value.toLowerCase()))||
            (isEmpty(co.isr) ?false: co.isr.name.toLowerCase().includes(e.target.value.toLowerCase()))||
            (isEmpty(co.osr) ?false: co.osr.name.toLowerCase().includes(e.target.value.toLowerCase()))||
            // co.comp.toLowerCase().includes(e.target.value.toLowerCase())||
            ( co.billno==''?false:co.billno.toLowerCase().includes(e.target.value.toLowerCase()))
        ));
        console.log("display:", display);
    }

    const openCO = (e)=>{
        let co = customerOrders.filter((co)=>co.uid==e)[0];
        props.history.push(`/customer-orders/view/${e}`,{co:co});
    };

    return(
        <>
        <Body2>
            <Title>Customer Orders</Title>
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box onSubmit={handleSubmit(merge)}>
                <header>
                    <NewDoc src={newDoc} onClick={newCustomer}/> <SearchBar onChange={updateSearchResults} placeholder="Search for a Customer"/>  {/*<Merge type='submit'>Merge</Merge> */}
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '2%'}}/> 
                        <col span="1" style={{width: '10%'}}/> 
                        <col span="1" style={{width: '5%'}}/>
                        <col span="1" style={{width: '10%'}}/>
                        <col span="1" style={{width: '13%'}}/>
                        <col span="1" style={{width: '13%'}}/>
                        <col span="1" style={{width: '4%'}}/>
                        <col span="1" style={{width: '10%'}}/>
                        <col span="1" style={{width: '10%'}}/>
                        <col span="1" style={{width: '6%'}}/>
                        <col span="1" style={{width: '8%'}}/>
                        <col span="1" style={{width: '8%'}}/>
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>Date</th>
                            <th>CO ID</th>
                            <th>Customer ID</th>
                            <th>Customer Name</th>
                            <th>Architect/Builder</th>
                            <th>Comp</th>
                            <th>ISR</th>
                            {/* <th>Total Pending</th> */}
                            <th>OSR</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Customer Order No.</th>
                        </tr>
                        {/* {console.log(display)} */}
                        {display.map((item,id) => 
                        <TR onClick={()=>openCO(item.uid)} status={item.status} key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.date}</td>
                            <td>{item.uid}</td>
                            <td>{item.customer.uid}</td>
                            <td>{`${item.customer.firstname} ${item.customer.lastname}`}</td>
                            <td>{isEmpty(item.architect)?'-':`${item.architect.firstname} ${item.architect.lastname}`}</td>
                            <td>{item.comp}</td>
                            <td>{isEmpty(item).isr?'-':item.isr.name}</td>
                            <td>{isEmpty(item).osr?'-':item.osr.name}</td>
                            {/* <td>{item.pending}</td> */}
                            <td>{item.total}</td>
                            <TD status={item.status}>{item.status}</TD>
                            <td>{item.billno}</td>
                            </TR>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        {/* {popup.comingSoon? <ComingSoon close={close}/>:""} */}
        </>
    );
}

const TD = styled.td`
    color: ${ ({theme, status})=> status=='pending'?theme.colors.orange:status=='blocked'?theme.colors.red:status=='cancelled'?theme.colors.darkGrey:theme.colors.green}
`;

const TR = styled.tr`
    background-color:${ ({theme, status})=> status=='blocked'?theme.colors.red:status=='cancelled'?theme.colors.darkGrey:status=='completed'?theme.colors.green:theme.colors.white}50;
    // background-opacity:0.5;
`;

export const NewDoc = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.5rem;
`;
export default CustomerOrders;



