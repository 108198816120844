import React from "react";

import NewCO from "./NewCustomerOrder";


const EditCO= (props) =>{
    const co = props.history.location.state.co;
    return <NewCO {...props} editing={true} metadata={co}/>

}

export default EditCO;