import React, { useContext, useEffect, useRef, useState } from 'react'
import {useForm} from 'react-hook-form'
import {Box, CustInfo, CustTables} from '../styles/CustomerOrder.style'
import {firestore} from '../firebase/config'
import {Buttons, CoInfo, Box2, Cust} from '../styles/CustomerOrder.style'
import {Text} from '../styles/NewCustomer.style'
import SelectSearch from 'react-select-search';
import {Input} from '../styles/CustomerOrder.style'
import "../styles/style.css";
import {Table} from '../styles/Customers.style'
import {useUser} from '../firebase/UserProvider'
import { unstable_renderSubtreeIntoContainer } from 'react-dom'
import styled from 'styled-components'
import custIcon from '../assets/cust_details.png'
import saveIcon from '../assets/save.png'


export const CoDetails = ({metadata, total, amounts, wallet}) =>{
    const [totalCal, setTotalCal] = useState(amounts.reduce((a, b) => a + b, 0))

    // useEffect( ()=>{
    //     setTotalCal( Math.round((amounts.reduce((a, b) => a + b, 0))*100)/100 )
    // },[amounts])
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    return (
        <>
        <CoInfo co={metadata}>
            {/* {console.log('totalCAl: ', totalCal)}
            {console.log('amounts: ', amounts)} */}
            <colgroup>
                <col span="1" style={{width: '50%%'}}/>
                <col span="1" style={{width: '50%'}}/>
            </colgroup>
            <tbody>
                <tr><th>Date</th><td>{metadata.date}</td></tr>
                <tr><th>ID</th><td>{metadata.uid}</td></tr>
                <tr><th>Status</th><td>{metadata.status}</td></tr>
                <tr><th>Total</th><td>{total}</td></tr>
                <tr><th>Closing Balance</th><td>{wallet}</td></tr>
                {/* <tr><th>Outstanding</th><td>{metadata.total-metadata.paid}</td></tr> */}
            </tbody>
            </CoInfo>
            <CoInfo co={metadata}>
            <colgroup>
                <col span="1" style={{width: '50%%'}}/>
                <col span="1" style={{width: '50%'}}/>
            </colgroup>
            <tbody>
                <tr><th>Architect</th><td>{isEmpty(metadata.architect)?'-':`${metadata.architect.firstname} ${metadata.architect.lastname}`}</td></tr>
                <tr><th>Comp</th><td>{metadata.comp}</td></tr>
                <tr><th>ISR</th><td>{isEmpty(metadata.isr)?'-':metadata.isr.name}</td></tr>
                <tr><th>OSR</th><td>{isEmpty(metadata.osr)?'-':metadata.osr.name}</td></tr>
                <tr><th>Customer Order No.</th><td>{metadata.billno}</td></tr>
                {/* <tr><th>Outstanding</th><td>{metadata.total-metadata.paid}</td></tr> */}
            </tbody>
            </CoInfo>
            </>
    );
};



export const CustDetails = ({metadata, margin}) => {
    return (
        <Cust margin={margin?margin:0}>
                <Text size='small'>About the </Text><Text size='small' color='orange'>Customer</Text>
                <CustTables >
                    <CustInfo>
                        <colgroup>
                            <col span="1" style={{width: '50%%'}}/>
                            <col span="1" style={{width: '50%'}}/>
                        </colgroup>
                        <tbody>
                            <tr><th>Customer ID</th><td>{`${metadata.customer.uid}`}</td></tr>
                            <tr><th>First Name</th><td>{`${metadata.customer.firstname}`}</td></tr>
                            <tr><th>Last Name</th><td>{metadata.customer.lastname}</td></tr>
                            <tr><th>Company</th><td>{metadata.customer.company}</td></tr>
                        </tbody> 
                    </CustInfo>

                    <CustInfo>
                        <colgroup>
                            <col span="1" style={{width: '50%%'}}/>
                            <col span="1" style={{width: '50%'}}/>
                        </colgroup>
                        <tbody>
                            <tr><th>Address</th><td>{`${metadata.customer.address}`}</td></tr>
                            <tr><th>Credit Auth</th><td>{metadata.customer.credit_auth}</td></tr>
                            <tr><th>Credit Limit</th><td>{metadata.customer.credit_limit}</td></tr>
                        </tbody>
                    </CustInfo>
                    </CustTables>
                    <CustIcon src={custIcon} />
            </Cust>
    );
}

const CustIcon=styled.img`
    position:absolute;
    z-index:2;
    bottom:0rem;
    right:1.5rem;
    width:3rem;
`;





export const ItemForm = ({ defaults, suppliers, pc, cancel, updateFunc}) => {
    const searchInput = useRef();
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [pcOptions, setPcOptions] = useState([]);
    const {register, handleSubmit, watch, formState: { errors }} = useForm();
    const [updated, setUpdated] = useState( {supplier:defaults.supplier.uid, prod_category:defaults.prod_category.uid, prod_collection:''});
    const {user} = useUser();

    useEffect(()=>{
        setSupplierOptions(suppliers.map((item)=> ({name:item.name, value:item.uid, item:item})));
        setPcOptions(pc.map((item)=> ({name:item.name, value:item.uid, item:item})));
    },[suppliers, pc]);
    
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const handleFilterSuppliers = (items) => {
        return (searchValue) => {
          if (searchValue.length === 0) {
            return supplierOptions;
          }
          const updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
            });
          return updatedItems;
        };
      };

      const handleChangeSup = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE sup:");
        // console.log("uid:", args[1].value);
        setUpdated( {...updated, supplier:args[1].value});
        
      };

      const handleChangeCol = (event) => {
        setUpdated( {...updated, prod_collection:event.target.value});
      };

      const handleChangeCat = (...args) => {
        setUpdated( {...updated, prod_category:args[1].value});
      };

      const handleFilterPc = (items) => {
        return (searchValue) => {
          if (searchValue.length === 0) {
            return pcOptions;
          }
          const updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.value.toString().includes(searchValue.toLowerCase());
            });
          return updatedItems;
        };
      };

      const callback = async (e) => {
        e.preventDefault();
        if(updated.prod_collection==''){
            alert('Please enter Product Collection')
            return 
        }
        if(!(updated.supplier)){
            alert('Please enter Supplier')
            return 
        }
        if(!(updated.prod_category)){
            alert('Please enter Product Category')
            return 
        }
        let newDoc = false;
        if (defaults.history == "")
            newDoc = true;
        let today = new Date();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        // console.log("user: ",user.phoneNumber);
        // TODO: check for admin here too
        let docRef = firestore.doc(`/staff/${user.phoneNumber}`);
        let doc = await docRef.get();
        let username = doc.data().name;
        console.log("In callback:", e.target[0].value);
        let history = `${date} ${time} \nUpdated by ${username} (${user.phoneNumber}) \n`;
        if(newDoc)
            history = `${date} ${time} \nCreated by ${username} (${user.phoneNumber}) \n`;
        if (defaults.prod_collection != e.target[0].value)
            history = `${history}Product Collection: ${defaults.prod_collection} --> ${e.target[0].value}\n` 
        if (defaults.prod_sno != e.target[1].value)
        history = `${history}Product SNo.: ${defaults.prod_sno} --> ${e.target[1].value}\n` 
        if (defaults.supplier.uid != updated.supplier)
        history = `${history}Supplier (ID): ${defaults.supplier.uid} --> ${updated.supplier}\n` 
        if (defaults.prod_category.uid != updated.prod_category)
        history = `${history}Product Category: ${defaults.prod_category.uid} --> ${updated.prod_category}\n` 
        if (defaults.price != e.target[4].value)
        history = `${history}Price: ${defaults.price} --> ${e.target[4].value}\n` 
        if (defaults.qty != e.target[5].value)
        history = `${history}Quantity: ${defaults.qty} --> ${e.target[5].value}\n` 
        if (defaults.gst != e.target[6].value)
        history = `${history}GST: ${defaults.gst} --> ${e.target[6].value}\n` 
        if (defaults.discount != e.target[7].value)
        history = `${history}Discount: ${defaults.discount} --> ${e.target[7].value}\n` 

            await updateFunc( {
                        uid: defaults.uid,
                        prod_collection:e.target[0].value,
                        prod_sno: e.target[1].value,
                        supplier: firestore.doc(`/suppliers/${updated.supplier}`),
                        prod_category: firestore.doc(`/product-categories/${updated.prod_category}`),
                        price:parseFloat(e.target[5].value),
                        qty: parseFloat(e.target[4].value),
                        gst:parseFloat(e.target[6].value),
                        discount:parseFloat(e.target[7].value),
                        history: `${history}\n\n-------------------------------\n\n${defaults.history}`
        }, defaults.uid, newDoc)
      };

    
    return (
        
        <form key={defaults.uid} onSubmit={(callback)}>
            {/* {console.log("def:", updated)} */}
        <Table><colgroup>
            <col span="1" style={{width: '4%'}}/>
            <col span="1" style={{width: '9%'}}/>
            <col span="1" style={{width: '13%'}}/>
            <col span="1" style={{width: '11%'}}/>
            <col span="1" style={{width: '10%'}}/>
            <col span="1" style={{width: '9%'}}/>
            <col span="1" style={{width: '7%'}}/>
            <col span="1" style={{width: '7%'}}/>
            <col span="1" style={{width: '9%'}}/>
            <col span="1" style={{width: '10%'}}/>
            <col span="1" style={{width: '5%'}}/>
            <col span="1" style={{width: '3%'}}/>
            <col span="1" style={{width: '3%'}}/>
        </colgroup><tbody>
        <TR>
            <td></td>
            {/* {console.log('supplier options:',supplierOptions)}
            {console.log('suppliers:',suppliers)} */}
            <td color='red'><Input 
                    error={errors.prod_collection && errors.prod_collection.type === "required"} 
                    key={1} 
                    type='text' {...register('prod_collection', { required: true })} 
                    placeholder = "Enter Prod Collection"
                    onChange={handleChangeCol}
                    defaultValue={defaults.prod_collection}/> 
            </td>

            <td><Input 
                    error={errors.prod_sno && errors.prod_sno.type === "required"} 
                    key={2} 
                    type='text' {...register('prod_sno', { required: true })} 
                    placeholder = "Enter Prod Sno/color"
                    defaultValue={defaults.prod_sno}/> 
            </td>

            <td color='red'><SelectSearch 
                    key={3} 
                    ref={searchInput}
                    error={errors.supplier && errors.supplier.type === "required"} 
                    options={supplierOptions} 
                    filterOptions={handleFilterSuppliers}
                    {...register('supplier', { required: true })} 
                    search
                    onChange={handleChangeSup}
                    placeholder="Supplier" />
            </td>

            <td><SelectSearch style={{border: 'black'}}
                    key={4} 
                    ref={searchInput}
                    error={errors.prod_category && errors.prod_category.type === "required"} 
                    options={pcOptions} 
                    filterOptions={handleFilterPc}
                    {...register('prod_category', { required: true })} 
                    search
                    onChange={handleChangeCat}
                    placeholder="Category" />
            </td>


            <td><Input 
                    error={errors.qty && errors.qty.type === "required"} 
                    key={6} 
                    type='number' step="any" {...register('qty', { required: true })} 
                    placeholder = "Quantity"
                    defaultValue={parseFloat(defaults.qty)}/> 
            </td>

            <td><Input 
                    error={errors.price && errors.price.type === "required"} 
                    key={5} 
                    type='number' step="any" {...register('price', { required: true })} 
                    placeholder = "Price"
                    defaultValue={parseFloat(defaults.price)}/> 
            </td>

            <td><Input 
                    error={errors.gst && errors.gst.type === "required"} 
                    key={7} 
                    type='number' step="any" {...register('gst', { required: true })} 
                    placeholder = "GST"
                    defaultValue={parseFloat(defaults.gst)}/> 
            </td>

            <td><Input 
                    error={errors.discount && errors.discount.type === "required"} 
                    key={8} 
                    type='number' step="any" {...register('discount', { required: true })} 
                    placeholder = "Discount"
                    defaultValue={parseFloat(defaults.discount)}/> 
            </td>

            <td>{(Math.round(watch('price')*watch('qty')*(1+watch('gst')/100)*(1-watch('discount')/100)*100)/100).toString()}</td>
            <td></td>
            <td><SaveButton><SaveIcon src={saveIcon}/></SaveButton></td><td><Buttons onClick={cancel}>x</Buttons></td>

        </TR>
        </tbody></Table></form>
    );
}

const SaveIcon = styled.img`
    margin: auto 1rem;
    padding: 0;
    width: 1.3rem;
`;

const SaveButton = styled.button`
    margin:0;
    background:none;
    border:none;
    padding:0;
`;

const TR = styled.tr`
    border: 1px solid ${ ({theme,color}) => color=="red"?theme.colors.red:'none'}
    // border-radius : 0.2rem
`;