import React from "react";
import NewSupplier from "./NewSupplier";



const EditSupplier= (props) =>{
    const user = props.history.location.state.user;
    const userProfile = {
        uid: user.uid,
        name: user.name,
    };
    return <NewSupplier {...props} editing={true} user={userProfile}/>

}

export default EditSupplier;