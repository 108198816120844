import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const MergeArchitect = (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({uid:[],
        firstname:[],
        lastname:[],
        company:[],
        address:[],
        dis_slab_1:[],
        dis_slab_2:[]});

    useEffect( ()=> {
        const refs = props.history.location.state.refs;
        // console.log("refs: ",refs);
        let obj = {uid:[],
            firstname:[],
            lastname:[],
            company:[],
            address:[],
            dis_slab_1:[],
            dis_slab_2:[]};
        refs.forEach( (data) => { obj = {uid:[...obj.uid, data.uid], 
                                                firstname:[...obj.firstname,data.firstname],
                                                lastname:[...obj.lastname, data.lastname],
                                                company:[...obj.company, data.company],
                                                address: [...obj.address, data.address],
                                                dis_slab_1:[...obj.dis_slab_1, data.dis_slab_1],
                                                dis_slab_2:[...obj.dis_slab_2, data.dis_slab_2]}});
        setUser(obj);
        //set values
        setValue('uid', obj.uid[0]);
        setValue('firstname', obj.firstname[0]);
        setValue('lastname', obj.lastname[0]);
        setValue('company', obj.company[0]);
        setValue('dis_slab_1', obj.dis_slab_1[0]);
        setValue('dis_slab_2', obj.dis_slab_2[0]);
        setValue('address', obj.address[0]);

        // console.log("obj: ",obj);
        
    }, []);

    const cancel = () => {
        props.history.push('/architects');
    }

    const merge = async (architect) => {
        setLoading(true)
        console.log("merging: ",architect);
        let refs = props.history.location.state.refs;
        let COs = [];
        let cosToUpdate = [];  // all co ids except the customer finally chosen
        // delete all objects
        // create a new object with the updated details
        // update all records (customer orders, payments etc)
        refs.forEach( async (ref) => {
            const a = firestore.doc(`/architects/${ref.uid}`);
            COs = [...COs, ...ref.COs];
            if(ref.uid!=architect.uid)
                cosToUpdate = [...cosToUpdate, ...ref.COs];
            await a.delete();
        });
        const docRef = firestore.doc(`/architects/${architect.uid}`);

        //updating all the customer orders
        cosToUpdate.forEach( async (co) => {
            let CoRef = firestore.doc(`/customer-orders/${co}`);
            CoRef.update({architect: docRef})
        });

        await docRef.set({...architect, COs:COs});
        props.history.replace('/architects');
        setLoading(false)
    };
    
    return(
        <Body>
            <Title>Merge Architects</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(merge)}>
                <Table><tbody>
                    {/* {console.log("to be merged: ", user)} */}
                    <tr>
                        <td>Architect ID</td><td><Select  
                                                    key={1}  
                                                    {...register('uid')}>
                                                    {user.uid.map( (uid,id) => <Option key={id} value={uid}>{uid}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>First Name</td><td><Select 
                                                    key={2}  
                                                    {...register('firstname')}>
                                                    {user.firstname.map( (firstname,id) => <Option key={id} value={firstname}>{firstname}</Option>)}
                                                </Select> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Last Name</td><td><Select 
                                                    key={3}  
                                                    {...register('lastname')}>
                                                    {user.lastname.map( (lastname,id) => <Option key={id} value={lastname}>{lastname}</Option>)}
                                                </Select> 
                                                </td>
                    </tr>
                    <tr>
                        <td>Company</td><td><Select  
                                                    key={4}  
                                                    {...register('company')}>
                                                    {user.company.map( (company,id) => <Option key={id} value={company}>{company}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>Address</td><td><Select 
                                                    key={5}  
                                                    {...register('address')}>
                                                    {user.address.map( (address,id) => <Option key={id} value={address}>{address}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr> 
                        <td>Discount Slab 1</td><td><Select  
                                                    key={6}  
                                                    {...register('dis_slab_1')}>
                                                    {user.dis_slab_1.map( (dis_slab_1,id) => <Option key={id} value={dis_slab_1}>{dis_slab_1}</Option>)}
                                                </Select>  </td>
                    </tr>
                    <tr>
                        <td>Discount Slab 2</td><td><Select 
                                                    key={7}  
                                                    {...register('dis_slab_2')}>
                                                    {user.dis_slab_2.map( (dis_slab_2,id) => <Option key={id} value={dis_slab_2}>{dis_slab_2}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {/* {props.editing? <Button onClick={handleSubmit(merge)} color={'green'}>Merge</Button> : */}
                            <Button type = 'submit' value='Submit' color={'green'}>Merge</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
    );
}

export default MergeArchitect;

    
