import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'


const NewArchitect =  (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [exists, setExists] = useState(false);
    const [content, setContent] = useState({header: "Architect Already Exists", text: ""});
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false)

    const close = () =>{
        setExists(false);
    }

    const edit = ()=> {
        props.history.push(`/architects/edit-architect/${user.uid}`, {user : user});
    }

    const createUser = async (user) =>{
        setLoading(true)
        setUser(user);
        const doc =  await firestore.doc(`/architects/${user.uid}`).get();
        if(doc.exists){
            // console.log(doc.data())
            setContent({...content, text: `ID: ${doc.data()['uid']}\n
                                            Name: ${doc.data()['firstname']} ${doc.data()['lastname']}\n
                                            Company: ${doc.data()['company']}\n
                                            Address: ${doc.data()['address']}\n
                                            Discount Slab 1: ${doc.data()['dis_slab_1']}\n
                                            Discount Slab 2: ${doc.data()['dis_slab_2']}\n`});
            setExists(true);
        } 
        else {
            const docRef = firestore.doc(`/architects/${user.uid}`);
            // create user object
            const userProfile = {
                COs:[],
                uid: user.uid,
                firstname: user.firstname,
                lastname: user.lastname,
                address: user.address,
                company: user.company,
                dis_slab_1: user.dis_slab_1,
                dis_slab_2 : user.dis_slab_2
            };

            // write to Cloud Firestore
            await docRef.set(userProfile);
            props.history.push('/architects');
             
        }
        setLoading(false)
    }

    const cancel = () => {
        props.history.push('/architects');
    }

    const update = async (newArchitect) =>{
        console.log(newArchitect);
        const docRef = firestore.doc(`/architects/${props.user.uid}`);
        await docRef.update({...newArchitect, uid:props.user.uid});
        props.history.push('/architects');
    }


    return(
        <>
        <Body>
            <Title>New Architect</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(createUser)}>
                <Table><tbody>
                    
                    <tr>
                        <td>Architect ID</td><td><Input 
                                                    error={errors.uid && errors.uid.type == "required"} 
                                                    key={1} 
                                                    type='tel' {...register('uid', { required: !props.editing })} 
                                                    placeholder = "Architect ID"
                                                    disabled={props.editing?true:false} 
                                                    defaultValue={props.editing?props.user.uid:""}
                                                    /></td>
                    </tr>
                    <tr>
                        <td>First Name</td><td><Input 
                                                    error={errors.firstname && errors.firstname.type == "required"} 
                                                    key={2} 
                                                    type='text' {...register('firstname', { required: true })} 
                                                    placeholder = "First Name"
                                                    defaultValue={props.editing?props.user.firstname:""}/> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Last Name</td><td><Input 
                                                error={errors.lastname && errors.lastname.type === "required"} 
                                                key={3} 
                                                type='text' {...register('lastname', { required: true })} 
                                                placeholder = "Last Name"
                                                defaultValue={props.editing?props.user.lastname:""}/> 
                                                </td>
                    </tr>
                    <tr>
                        <td>Company</td><td><Input key={4} type='text' {...register('company')} placeholder = "Company (optional)"
                                                defaultValue={props.editing?props.user.company:""}/> </td>
                    </tr>
                    <tr>
                        <td>Address</td><td><Input 
                                                error={errors.address && errors.address.type === "required"} 
                                                key={5} 
                                                type='text' {...register('address', { required: true })} 
                                                placeholder = "Address"
                                                defaultValue={props.editing?props.user.address:""}/> </td>
                    </tr>
                    <tr> 
                        <td>Discount Slab 1</td><td><Input 
                                                        error={errors.address && errors.address.type === "required"} 
                                                        key={6} 
                                                        type='number' {...register('dis_slab_1', { required: true })} 
                                                        placeholder = "Discount Slab 1"
                                                        defaultValue={props.editing?props.user.dis_slab_1:0}/> </td>
                    </tr>
                    <tr>
                        <td>Discount Slab 2</td><td><Input 
                                                    error={errors.credit_limit && errors.credit_limit.type === "required"} 
                                                    key={7} 
                                                    disabled={watch('dis_slab_1') === 0?true:false} 
                                                    type='number' {...register('dis_slab_2')} 
                                                    placeholder = "Discount Slab 2"
                                                    defaultValue={props.editing?props.user.dis_slab_2:0}/> </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {props.editing? <Button onClick={handleSubmit(update)} color={'green'}>Update</Button> :
                            <Button type = 'submit' value='Submit' color={'green'}>Create Architect</Button>}
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
        {exists? <ExistPopup content = {content.text} header={content.header} edit={edit} close={close}/> :""}
        </>
    );
}

export default NewArchitect;

