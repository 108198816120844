import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const MergeProductCategory = (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }} = useForm();
    const [user, setUser] = useState({uid:[], name:[], max_discount:[]});
    const [loading, setLoading] = useState(false)

    useEffect( ()=> {
        const refs = props.history.location.state.refs;
        // console.log("refs: ",refs);
        let obj = {uid:[], name:[], max_discount:[]};
        refs.forEach( (data) => { obj = {uid:[...obj.uid, data.uid], name:[...obj.name,data.name], max_discount:[...obj.max_discount,data.max_discount]}});
        setUser(obj);
        //set values
        setValue('uid', obj.uid[0]);
        setValue('name', obj.name[0]);
        setValue('max_discount', obj.max_discount[0]);
        // console.log("obj: ",obj);
    }, []);

    const cancel = () => {
        props.history.push('/product-categories');
    }

    const merge = async (product) => {
        setLoading(true)
        console.log("merging: ",product);
        let refs = props.history.location.state.refs;
        let COs = [];
        let cosToUpdate = [];  
        // delete all objects
        // create a new object with the updated details
        // update all records (customer orders, payments etc)
        refs.forEach( async (ref) => {
            const a = firestore.doc(`/product-categories/${ref.uid}`);
            COs = [...COs, ...ref.COs];
            if(ref.uid!=product.uid)
                cosToUpdate = [...cosToUpdate, ...ref.COs];
            await a.delete();
        });

        let docRef = firestore.doc(`/product-categories/${product.uid}`);

        //updating all the customer orders
        cosToUpdate.forEach( async (co) => {
            let ref = firestore.doc(`/customer-orders/${co.co}/order/${co.uid}`)
            ref.update({prod_category: docRef})
        });

        docRef = firestore.doc(`/product-categories/${product.uid}`);
        docRef.set({...product, COs:COs});
        props.history.replace('/product-categories');
        setLoading(false)
    };
    
    return(
        <Body>
            <Title>Merge Categories</Title>
            {/* {console.log("errors:",errors)}; */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(merge)}>
                <Table><tbody>
                    {/* {console.log("to be merged: ", user)} */}
                    <tr>
                        <td>ID</td><td><Select  
                                                    key={1}  
                                                    {...register('uid')}>
                                                    {user.uid.map( (uid,id) => <Option key={id} value={uid}>{uid}</Option>)}
                                                </Select> </td>
                    </tr>
                    <tr>
                        <td>Name</td><td><Select 
                                                    key={2}  
                                                    {...register('name')}>
                                                    {user.name.map( (name,id) => <Option key={id} value={name}>{name}</Option>)}
                                                </Select> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Max Discount</td><td><Select 
                                                    key={3}  
                                                    {...register('max_discount')}>
                                                    {user.max_discount.map( (name,id) => <Option key={id} value={name}>{name}</Option>)}
                                                </Select> 
                                                    </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {/* {props.editing? <Button onClick={handleSubmit(merge)} color={'green'}>Merge</Button> : */}
                            <Button type = 'submit' value='Submit' color={'green'}>Merge</Button>
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
    );
}

export default MergeProductCategory;

    
