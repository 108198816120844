import React, { useContext, useEffect, useState } from 'react'

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const makeExcelData = (display) => {
    let toret = []
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
        display.forEach(async (item,id) => {
            
            item.items.forEach( (prod, iid) => {

                let b = {
                    Date: item.date,
                    cname: `${item.customer.firstname} ${item.customer.lastname}`,
                    orderno: item.billno,
                    amt: item.total,
                    isr: isEmpty(item.isr)?'-':item.isr.name,
                    prod_collection: prod.prod_collection,
                    sno: prod.prod_sno,
                    supplier: prod.supplier?.name,
                    prod_category: prod.prod_category?.name,
                    qty: prod.qty,
                    price: prod.price,
                    gst: prod.gst,
                    discount: prod.discount,
                    total: Math.round(prod.qty * prod.price*(1+prod.gst/100)*(1-prod.discount/100)*100)/100,
                    receiving_date: prod.receiving_date?prod.receiving_date.toDate().toLocaleDateString():'-',
                    cId: item.customer.uid,
                    coId: item.uid,
                    co_status:item.status,
                    architect: isEmpty(item.architect)?'-':`${item.architect.firstname} ${item.architect.lastname}`,
                    comp: item.comp,
                    osr: isEmpty(item.osr)?'-':item.osr.name,
                };
                

                
                toret = [...toret, b]
            })
        })
        return toret;
}

const ExcelExport = (props) =>{
    // const [display, setDisplay] = useState(props.history.location.state.data);
    // const [data, setData] = useState([]);
    
    useEffect( ()=>{
        setTimeout(() => {
            props.history.goBack()
        }, 2000);
    }, [])
    
    return (
        <ExcelFile hideElement={true}>
            <ExcelSheet data={props.history.location.state.data} name="Customer Orders">
                <ExcelColumn label="Date" value="Date"/>
                <ExcelColumn label="Customer Name" value="cname"/>
                <ExcelColumn label="Order no." value="orderno"/>
                <ExcelColumn label="CO Total" value="amt"/>
                <ExcelColumn label="ISR" value="isr"/>
                <ExcelColumn label="Product Collection" value="prod_collection"/>
                <ExcelColumn label="Product Sno." value="sno"/>
                <ExcelColumn label="Supplier" value="supplier"/>
                <ExcelColumn label="Product Category" value="prod_category"/>
                <ExcelColumn label="Quantity" value="qty"/>
                <ExcelColumn label="GST(%)" value="gst"/>
                <ExcelColumn label="Discount(%)" value="discount"/>
                <ExcelColumn label="Total" value="total"/>
                <ExcelColumn label="Receiving Date" value="receiving_date"/>
                <ExcelColumn label="Customer ID" value="cId"/>
                <ExcelColumn label="CO Status" value="co_status"/>
                <ExcelColumn label="Architect" value="architect"/>
                <ExcelColumn label="Comp" value="comp"/>
                <ExcelColumn label="OSR" value="osr"/>
                {/* <ExcelColumn label="date" value="Date"/> */}


            </ExcelSheet>
        </ExcelFile>
        // {props.history.pop}
    )
};

export default ExcelExport;
