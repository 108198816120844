import React from "react";
import NewProductCategory from "./NewProduct";

const EditProductCategory= (props) =>{
    const user = props.history.location.state.user;
    const userProfile = {
        uid: user.uid,
        name: user.name,
    };
    return <NewProductCategory {...props} editing={true} user={userProfile}/>

}

export default EditProductCategory;