// search inspired from : https://levelup.gitconnected.com/building-a-simple-dynamic-search-bar-in-react-js-f1659d64dfae

import React, { useContext, useEffect, useState } from 'react'
import Title from '../themes/Title.style'
import {useForm} from 'react-hook-form'
import {New, Body2, SearchBar, SearchButton, Box, Merge, Table} from '../styles/Customers.style'
import {firestore} from '../firebase/config'
import ComingSoon from '../components/ComingSoon'
import {Buttons} from '../styles/CustomerOrder.style'
import styled from 'styled-components'
import { NewDoc } from './CustomerOrders'
import newDoc from '../assets/new_doc.png'
import { Loading } from './CustomerOrder'

const Payments = (props) => {
    const {handleSubmit, register, setValue} = useForm();
    const [payments, setPayments] = useState([]);
    const [display, setDisplay] = useState([]);
    const [popup, setPopup] = useState({comingSoon:false});
    const [customer, setCustomer] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect( ()=>{

        async function fetchData (){
            await firestore.collection("payments").get().then((querySnapshot) => {
                querySnapshot.forEach(doc => {
                    if(doc.id!='id'){
                        setPayments(payments => [...payments, {...doc.data(), checked:false}]);
                        setDisplay(display=> [...display, {...doc.data(), checked:false}]);
                    }
                });
            });
            setLoading(false) 
        }

        async function fetchSpecific () {
            var customer = firestore.doc(`/customers/${props.location.state.uid}`);
            customer = (await customer.get()).data();
            setCustomer(customer);
            var payments = customer.payments;
            var custPay = []
            for(var i =0;i<payments.length;i++){
                var payment = firestore.doc(`/payments/${payments[i]}`);
                payment = (await payment.get()).data();
                custPay = [...custPay, {...payment, checked:false} ];
                // console.log(custPay);
            }
            setPayments(payments => custPay);
            setDisplay(display=> custPay);
            setLoading(false)
        };

        props.location?.state?.uid? fetchSpecific() : fetchData();
    },[]);

    const newPayment =()=>{
        props.history.push('/payments/new-payment')
    };

    const close = ()=>{setPopup({...popup, comingSoon:false})}

    const checked = (e) => {
        // console.log(e.target.name, e.target.checked);
        let a = [...payments];
        let idx = a.findIndex((cust)=> cust.uid == e.target.name);
        a[idx].checked = e.target.checked;
        setPayments(payments=>a);
        let b= [...display];
        let idx2 = b.findIndex((cust)=> cust.uid == e.target.name);
        b[idx2].checked = e.target.checked;
        setDisplay(display=>b);
        // console.log(display);
    }


    const updateSearchResults = (e)=>{ 
        setDisplay(payments.filter(payment => 
            payment.uid.toString().includes(e.target.value.toLowerCase()) ||
            payment.date.toLowerCase().includes(e.target.value.toLowerCase()) ||
            payment.description.toLowerCase().includes(e.target.value.toLowerCase())||
            payment.mode.toLowerCase().includes(e.target.value.toLowerCase())||
            payment.status.toLowerCase().includes(e.target.value.toLowerCase())||
            payment.customer.toString().includes(e.target.value.toLowerCase())
            // payment.pending.toString().includes(e.target.value.toLowerCase())||
        ));
        // console.log("display:", display);
    }

    const cancelPayment = (payment) => {
        props.history.push(`/payments/cancel/${payment.uid}`, {payment:payment})
    };

    return(
        <>
        <Body2>
            <Title style={{marginBottom:0}}>Payments </Title>
            {<Title style={{fontSize:'1rem', fontWeight:'10',marginTop:0}}>{props.location?.state?.uid?customer==''?'':`${customer.firstname} ${customer.lastname}, ${customer.uid}` :''}</Title>}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Box>
                <header 
                style={{display:'flex'}}
                >
                    <NewDoc onClick={newPayment} src={newDoc}/> <SearchBar onChange={updateSearchResults} placeholder="Search by Customer ID, date, Remarks, Payment ID, Mode, Status"/> 
                    {props.location?.state?.uid?<Balance>Closing Balance: {customer.wallet}</Balance>:''}
                </header>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: '4%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '25%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                        <col span="1" style={{width: '9%'}}/>
                    </colgroup>
                    <tbody>
                        <tr id='head'>
                            <th></th>
                            <th>Payment ID</th>
                            <th>Date</th>
                            <th>Customer ID</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            {/* <th>Total Pending</th> */}
                            <th>Mode</th>
                            <th>Status</th>
                            <th>Cancel</th>

                        </tr>
                        {/* {console.log(display)} */}
                        {display.map((item,id) => 
                        <tr  key={item.uid}><td><input onClick={checked} checked={item.checked} type="checkbox" {...register(`${item.uid}`)} /></td>
                            <td>{item.uid}</td>
                            <td>{item.date}</td>
                            <td>{item.customer}</td>
                            <td>{item.description}</td>
                            <td style={{color:'red'}}>{item.debit}</td>
                            <td style={{color:'green'}}>{item.credit}</td>
                            <td>{item.mode}</td>
                            {/* <td>{item.pending}</td> */}
                            <td>{item.status}</td>
                            <td><Buttons onClick={()=>{cancelPayment(item)}}>Cancel</Buttons></td>
                            </tr>
                        )}

                    </tbody>
                </Table>
            </Box>}
            
        </Body2>
        {popup.comingSoon? <ComingSoon close={close}/>:""}
        </>
    );
}

const Balance = styled.p`
    color: ${ ({theme}) => theme.colors.darkGrey};
    font-weight: 899;
    font-family: ${ ({theme}) => theme.fonts.titles}, sans-serif;
    font-size: 1rem;
    letter-spacing: 0px;
    line-height: 1.2;
    margin:auto 1rem;
    margin-left: auto;
`;

export default Payments;



