import React, { useContext, useEffect, useState } from "react";
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {firestore} from '../firebase/config'
import Title from '../themes/Title.style'
import Body from '../themes/Body.style'
import {Table,Input,Button, Option,Select,Form, Text, AuthInfoS} from '../styles/NewCustomer.style'
import ExistPopup from '../components/ExistPopup'
import { Loading } from './CustomerOrder'

const NewProductCategory =  (props) => {
    const {register,setValue, handleSubmit, watch,  formState: { errors }, getValues} = useForm();
    const [exists, setExists] = useState(false);
    const [currentId, setCurrentId] = useState(0);
    const [content, setContent] = useState({header: "Product Category Already Exists", text: ""});
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false)
    const [dis, setDis] = useState(0);
   
    useEffect(() => {
        const fetchId = async () => {
            const idRef = firestore.doc(`/product-categories/id`);
            const id = (await idRef.get()).data()['id'];
            setCurrentId(id+1);
        };
        fetchId();

    },[]);

    const close = () =>{
        setExists(false);
    }

    const edit = ()=> {
        props.history.push(`/product-categories/edit-product-category/${user.uid}`, {user : user});
    }

    const createUser = async (user) =>{
        setLoading(true)
        setUser(user);
        const idRef = firestore.doc(`/product-categories/id`);
        const id =  (await idRef.get()).data()['id'];
        idRef.update({'id': id+1});
        const docRef = firestore.doc(`/product-categories/${id+1}`);
        // create user object
        const userProfile = {
            uid: id+1,
            name: user.name,
            max_discount: user.dis,
            COs:[]
        };

        // write to Cloud Firestore
        await docRef.set(userProfile);
        props.history.push('/product-categories');
        setLoading(false)
    }

    const cancel = () => {
        props.history.push('/product-categories');
    }

    const update = async (newProduct) =>{
        console.log(newProduct);
        const docRef = firestore.doc(`/product-categories/${props.user.uid}`);
        await docRef.update({...newProduct, uid:user.uid});
        props.history.push('/product-categories');
    }

    const handleDisChange = (...args) => {
        // console.log(args[0].target.value)
        setDis(parseInt(args[0].target.value))
    }
    return(
        <>
        <Body>
            <Title>New Product Category</Title>
            {/* {console.log("errors:",dis)} */}
            {loading? <Loading><div class='dot-spin'/></Loading> :
            <Form onSubmit = {handleSubmit(createUser)}>
                <Table><tbody>
                    
                    <tr>
                        <td>ID</td><td>{currentId}</td>
                    </tr>
                    <tr>
                        <td>Product Category Name</td><td><Input 
                                                    error={errors.name && errors.name.type === "required"} 
                                                    key={2} 
                                                    type='text' {...register('name', { required: true })} 
                                                    placeholder = "Enter Name"
                                                    defaultValue={props.editing?props.user.name:""}/> 
                                                    </td>
                    </tr>
                    <tr>
                        <td>Maximum Discount (%)</td><td><Input 
                                                    error={(errors.dis && errors.dis.type === "required") || dis>100} 
                                                    key={3} 
                                                    type='number' {...register('dis', { required: true, max:100 })} 
                                                    placeholder = "Enter Maximum Discount"
                                                    onChange={handleDisChange}
                                                    defaultValue={props.editing?props.user.max_discount:""}/> 
                                                    </td>
                    </tr>
                    <tr><td></td>
                        <td>
                            {props.editing? <Button onClick={handleSubmit(update)} color={'green'}>Update</Button> :
                            <Button type = 'submit' value='Submit' color={'green'}>Create Category</Button>}
                            <Button color='red' onClick={cancel}>Cancel</Button>
                        </td>
                    </tr>
                </tbody></Table>
                
            </Form>}

            
            
        </Body>
        {exists? <ExistPopup content = {content.text} header={content.header} edit={edit} close={close}/> :""}
        </>
    );
}

export default NewProductCategory;

